<template>
	<div 
		class="group"
		:style="{
			'background-color': fieldsGroup[0].type === 'combo' ? fieldsGroup[0].styles['background-color'] : 'transparent',
			'padding-top': fieldsGroup[0].type === 'combo' ? '15px' : '0',
			'padding-bottom': fieldsGroup[0].type === 'combo' ? '15px' : '0',
			'padding-left': bannerStyles['padding-left'],
			'padding-right': bannerStyles['padding-right']
		}"
	>
		<div 
			class="field_combo-icon"
			v-if="fieldsGroup[0].type === 'combo'"
		>
      <img
        class="icon"
        :src="fieldsGroup[0].image ? createImageURL(fieldsGroup[0].image) : strapiHost + fieldsGroup[0].value"
      >
    </div>
		<div class="field_combo">
			<FieldValue
				v-for="(field, index) in fieldsGroup.filter(f => f.type !== 'combo')"
				:key="index"
				:field="field"
				class="field_value"
			/>
		</div>
	</div>
</template>

<script>
import FieldValue from '@/components/FieldValue'

export default {
	props: ['fieldsGroup', 'bannerStyles'],
	components: {
		FieldValue
	},
	methods: {
		createImageURL(image) {
			if (image?.url) {
				return this.ENV_STRAPI_HOST + image.url;
			} else if (image) {
				return URL.createObjectURL(image);
			} 
		}
	},
	computed: {
		strapiHost() {
			return this.$store.getters.STRAPI_HOST;
		}
	}
}
</script>

<style lang="sass">
.group
	display: flex
	.field
		&_combo-icon
			width: 64px
			margin-right: 10px
			.icon
				width: 100%
		&_combo
			flex: 1

</style>
