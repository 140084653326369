<template lang="html">
  <div
    class="field"
    :style="{...fieldStyles, 'width': 'inherit'}"
    @click="onLinkClicked"
  >
    <!-- Date -->
    <div v-if="field.type === 'date'">
      {{ field.value | date }}
    </div>
    <!-- Header -->
    <div v-else-if="field.type === 'headerh1' 
                    || field.type === 'headerh2'
                    || field.type === 'headerh1group'
                    || field.type === 'headerh2group'">
      {{ field.value }}
    </div>
    <!-- Text -->
    <div v-else-if="field.type === 'text' || field.type === 'textgroup'" ref="text_container"></div>
    <!-- Image -->
    <img
      class="image"
      v-else-if="(field.type === 'image' || field.type === 'imagegroup') && field.image"
      :style="{'width': fieldStyles['width']}"
      :src="createImageURL(field.image)"
    >
    <!-- Video -->
    <iframe 
      class="video-frame"
      v-else-if="(field.type === 'youtube' || field.type === 'youtubegroup') && field.value.length"
      :src="field.value"
      frameborder="0" 
      allowfullscreen
    ></iframe>
    <!-- Bulletpoints -->
    <ul class="bp-list" v-else-if="(field.type === 'bulletpoints' || field.type === 'bulletpointsgroup') && field.value.length">
      <li
        class="bp-list__line"
        v-for="(line, index) in getBulletpointsLines()"
        :key="index"
      >{{ line }}</li>
    </ul>
    <!-- Feedback -->
    <div
      class="feedback" 
      v-else-if="field.type === 'feedback' && field.value && field.value.startsWith('assetId')"
      :style="{'justify-content': fieldStyles['justify-content']}"
    >
      <!-- Good icons -->
      <img
        class="feedback-icon"
        v-if="feedbackIcons['goodDefault'] && feedbackPreview.good === 'default'"
        :src="ENV_STRAPI_HOST + feedbackIcons['goodDefault']"
        @mouseover="feedbackPreview.good = 'hover'"
        :title="feedbackIcons['goodTitle']"
      >
      <img 
        class="feedback-icon"
        v-if="feedbackIcons['goodHover'] && feedbackPreview.good === 'hover'"
        :src="ENV_STRAPI_HOST + feedbackIcons['goodHover']"
        @click="onFeedbackActive('good')"
        @mouseleave="feedbackPreview.good = 'default'"
        :title="feedbackIcons['goodTitle']"
      >
      <img
        class="feedback-icon"
        v-if="feedbackIcons['goodActive'] && feedbackPreview.good === 'active'"
        :src="ENV_STRAPI_HOST + feedbackIcons['goodActive']"
        :title="feedbackIcons['goodTitle']"
      >
      <!-- Neutral icons -->
      <img
        class="feedback-icon"
        v-if="feedbackIcons['neutralDefault'] && feedbackPreview.neutral === 'default'"
        :src="ENV_STRAPI_HOST + feedbackIcons['neutralDefault']"
        @mouseover="feedbackPreview.neutral = 'hover'"
        :title="feedbackIcons['neutralTitle']"
      >
      <img
        class="feedback-icon"
        v-if="feedbackIcons['neutralHover'] && feedbackPreview.neutral === 'hover'"
        :src="ENV_STRAPI_HOST + feedbackIcons['neutralHover']"
        @click="onFeedbackActive('neutral')"
        @mouseleave="feedbackPreview.neutral = 'default'"
        :title="feedbackIcons['neutralTitle']"
      >
      <img
        class="feedback-icon"
        v-if="feedbackIcons['neutralActive'] && feedbackPreview.neutral === 'active'"
        :src="ENV_STRAPI_HOST + feedbackIcons['neutralActive']"
        :title="feedbackIcons['neutralTitle']"
      >
      <!-- Bad -->
      <img
        class="feedback-icon"
        v-if="feedbackIcons['badDefault'] && feedbackPreview.bad === 'default'"
        :src="ENV_STRAPI_HOST + feedbackIcons['badDefault']"
        @mouseover="feedbackPreview.bad = 'hover'"
        :title="feedbackIcons['badTitle']"
      >
      <img
        class="feedback-icon"
        v-if="feedbackIcons['badHover'] && feedbackPreview.bad === 'hover'"
        :src="ENV_STRAPI_HOST + feedbackIcons['badHover']"
        @click="onFeedbackActive('bad')"
        @mouseleave="feedbackPreview.bad = 'default'"
        :title="feedbackIcons['badTitle']"
      >
      <img
        class="feedback-icon"
        v-if="feedbackIcons['badActive'] && feedbackPreview.bad === 'active'"
        :src="ENV_STRAPI_HOST + feedbackIcons['badActive']"
        :title="feedbackIcons['badTitle']"
      >
    </div>
  </div>
</template>

<script>
export default {
  props: ['field'],
  data: () => ({
    feedbackIcons: {
      'good': '',
      'neutral': '',
      'bad': ''
    },
    feedbackPreview: {
      good: 'default',
      neutral: 'default',
      bad: 'default'
    }
  }),
  methods: {
    createImageURL(image) {
      if (image?.url) {
        return this.ENV_STRAPI_HOST + image.url;
      } else if (image) {
        return URL.createObjectURL(image);
      } 
    },
    onLinkClicked() {
      if (this.field.action_url.length) {
        window.open(
          this.field.action_url,
          '_blank'
        )
      }
    },
    bindLinkStyle() {
      if (this.field.action_url.length) {
        return {
          "cursor": "pointer"
        }
      } else {
        return {
          "cursor": "default"
        }
      }
    },
    getBulletpointsLines() {
      return this.field.value.split('/bl');
    },
    onFeedbackActive(type) {
      this.feedbackPreview = {
        good: 'default',
        neutral: 'default',
        bad: 'default'
      }
      this.feedbackPreview[type] = 'active';
    }
  },
  watch: {
    'field': {
      handler(f) {
        if (f.type === 'text' || f.type === 'textgroup') {
          const lines = f.value.split(/\r?\n/g);
          const html = lines.reduce((sum, line, index) => {
            return index === 0
                  ? sum + line
                  : sum + '<br/>' + line
          }, '');
          this.$nextTick(() => {
            this.$refs['text_container'].innerHTML = html;
          });
        } else if (f.type === 'feedback'  && f.value && f.value.startsWith('assetId')) {
          const assetId = f.value.split('=')[1];
          const feedbackIconsStyle = this.$store.getters.teamFeedbackIcons.find(i => i.id == assetId) 
          this.feedbackIcons = feedbackIconsStyle.data;
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    fieldStyles() {
      const styles = {
        ...this.field.styles,
        'margin-bottom': this.field.styles['margin-bottom'] + 'px',
        'margin-top': this.field.styles['margin-top'] + 'px'
      };
      if ('font-size' in this.field.styles) {
        styles['font-size'] = this.field.styles['font-size'] + 'px';
      }
      if ('height' in this.field.styles) {
        styles['height'] = this.field.styles['height'] === 'auto'
                           ? this.field.styles['height']
                           : this.field.styles['height'] + 'px';
      }
      if ('width' in this.field.styles) {
        styles['width'] = this.field.styles['width'] === 'auto'
                          || this.field.styles['width'] === '100%'
                          ? this.field.styles['width']
                          : this.field.styles['width'] + 'px';
      }
      if (this.field.action_url.length) {
        styles['cursor'] = "pointer";
      }
      return styles;
    },
    image() {
      return this.field.image;
    }
  }
}
</script>

<style lang="sass" scoped>
.field
  white-space: pre-wrap
  &__link
    display: block
    font-size: inherit
    text-decoration: inherit
    color: inherit
    width: inherit
    object-fit: inherit
  
.bp-list
  font-size: inherit
  padding-left: 20px
  &__line
    font-size: inherit

.image
  max-width: 100%
  object-fit: inherit

.video-frame
  height: inherit
  width: 100%

.feedback
  display: flex
  &-icon
    width: 36px
    height: 36px
    cursor: pointer
    &:not(:last-child)
      margin-right: 5px

</style>

