<template>
    <div class="options-modal">
        <h2 class="options-modal__title">Banner <br/> appearance</h2>
        
        <form class="options-modal__form" @submit.prevent="">
            <div class="layout">
                <!-- SIZE -->
                <div class="param param_size">
                    <label class="param__label">Banner size:</label>
                    <div class="param__inputs-group">
                        <!-- WIDTH -->
                        <div class="param__input-container param__input-container_width">
                            <input
                                class="param__input param__input_width"
                                type="text"
                                v-model="width"
                                @keydown="arrowsHandler($event, 'width')"
                            >
                            <span class="param__px">px</span>
                        </div>
                        <!-- HEIGHT -->
                        <div class="param__input-container param__input-container_height">
                            <input
                                class="param__input param__input_height"
                                :class="{'param__input_height--auto': height === 'auto'}"
                                type="text"
                                v-model="height"
                                @keydown="arrowsHandler($event, 'height')"
                            >
                            <span
                                v-if="height !== 'auto'"
                                class="param__px"
                            >px</span>
                            <button
                                class="param__auto_height"
                                @click="height = 'auto'"
                                v-if="height !== 'auto'"
                            >auto</button>
                        </div>
                    </div>
                </div>
                <!-- BACKGROUND COLOR -->
                <div class="param param_background">
                    <label class="param__label">Background color:</label>
                    <div class="param__inputs-group param__inputs-group_color">
                        <div
                            class="param__input-box_color"
                            @click="$refs['color_hidden'].click()"
                        >
                            <div
                                :style="{'background': background}"
                                class="color"
                            ></div>
                            <input
                                class="param__input_hidden"
                                type="color"
                                ref="color_hidden"
                                v-model="background"
                            >
                        </div>
                        <div class="param__input-container param__input-container_background">
                            <input
                                class="param__input param__input_background"
                                type="text"
                                v-model="background"
                            >
                        </div>
                    </div>
                </div>
                <!-- VERTICAL PADDINGS -->
                <div class="param param_Vmargins">
                    <label class="param__label">Vertical margins:</label>
                    <div class="param__inputs-group">
                        <!-- MARGIN TOP -->
                        <div class="param__input-container param__input-container_pt">
                            <input
                                class="param__input param__input_pt"
                                type="text"
                                v-model="paddingTop"
                                @keydown="arrowsHandler($event, 'paddingTop')"
                            >
                            <span class="param__px">px</span>
                        </div>
                        <span
                            class="param__lock"
                            :class="{'active': verticalPaddingsLock}"
                            @click="verticalPaddingsLock = !verticalPaddingsLock"
                        ></span>
                        <!-- PADDING BOTTOM -->
                        <div class="param__input-container param__input-container_pb">
                            <input
                                class="param__input param__input_pb"
                                type="text"
                                v-model="paddingBottom"
                                @keydown="arrowsHandler($event, 'paddingBottom')"
                            >
                            <span class="param__px">px</span>
                        </div>
                    </div>
                </div>
                <!-- HORIZONTAL PADDINGS -->
                <div class="param param_Hmargins">
                    <label class="param__label">Horizontal margins:</label>
                    <div class="param__inputs-group">
                        <!-- PADDING LEFT -->
                        <div class="param__input-container param__input-container_pl">
                            <input
                                class="param__input param__input_pl"
                                type="text"
                                v-model="paddingLeft"
                                @keydown="arrowsHandler($event, 'paddingLeft')"
                            >
                            <span class="param__px">px</span>
                        </div>
                        <span
                            class="param__lock"
                            :class="{'active': horizontalPaddingsLock}"
                            @click="horizontalPaddingsLock = !horizontalPaddingsLock"
                        ></span>
                        <!-- PADDING RIGHT -->
                        <div class="param__input-container param__input-container_pr">
                            <input
                                class="param__input param__input_pr"
                                type="text"
                                v-model="paddingRight"
                                @keydown="arrowsHandler($event, 'paddingRight')"
                            >
                            <span class="param__px">px</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="buttons-container">
                <button 
                    class="input-button"
                    type="button"
                    @click="$emit('close')"
                >Cancel</button>
                <button 
                    class="input-button"
                    type="button"
                    @click="onSave"
                >Save</button>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    props: ['activeBanner'],
    data: () => ({
        verticalPaddingsLock: true,
        horizontalPaddingsLock: true,
        sizeLock: false,

        paddingTop: '',
        paddingBottom: '',
        paddingLeft: '',
        paddingRight: '',
        width: '',
        height: '',
        background: '',
    }),
    mounted() {
        this.paddingTop = this.bannerStyles['padding-top'];
        this.paddingBottom = this.bannerStyles['padding-bottom'];
        this.paddingLeft = this.bannerStyles['padding-left'];
        this.paddingRight = this.bannerStyles['padding-right'];
        this.width = this.bannerStyles['width'];
        this.height = this.bannerStyles['height'];
        this.background = this.bannerStyles['background'];
    },
    methods: {
        async onSave() {
            const styles = {
                'padding-top': this.paddingTop,
                'padding-bottom': this.paddingBottom,
                'padding-left': this.paddingLeft,
                'padding-right': this.paddingRight,
                'width': this.width,
                'height': this.height,
                'background': this.background
            }

            const newBannerData = { styles }

            const editedBanner = await this.$store.dispatch('editBanner', { newBannerData, bannerId: this.activeBanner.id });
            this.$store.commit('updateActiveBanner', editedBanner);
            this.$store.commit('updateAutoSaveTimer', 1);

            this.$emit('close')
        },
        arrowsHandler(e, type) {
            if (e.keyCode === 38) {
                // UP
                this[type]++;
            } else if (e.keyCode === 40) {
                // DOWN
                if (e.target.value > 0) {
                    this[type]--;
                }
            }
        },
        changePaddings(type, value) {
            if (type === 'top' || type === 'bottom') {
                if (this.verticalPaddingsLock) {
                    this.paddingTop = this.paddingBottom = value
                }
            } else if (type === 'left' || type === 'right') {
                if (this.horizontalPaddingsLock) {
                    this.paddingLeft = this.paddingRight = value
                }
            }
        }
    },
    computed: {
        bannerStyles() {
            return this.activeBanner.styles;
        }
    },
    watch: {
        paddingTop(value) {
            this.changePaddings('top', value);
        },
        paddingBottom(value) {
            this.changePaddings('bottom', value);
        },
        paddingLeft(value) {
            this.changePaddings('left', value);
        },
        paddingRight(value) {
            this.changePaddings('right', value);
        }
    }
}
</script>

<style lang="sass" scoped>
@import '../../assets/mixins/mixins'

.options-modal
    @include modal  
    &__form
        margin-top: 120px  
        .buttons-container
            padding-right: 31px

.layout
    font-family: $lato
    .param
        display: flex
        &:not(:last-child)
            margin-bottom: 20px
        &__label
            width: 150px
            text-align: right
            padding-right: 20px
            font-size: 14px
        &__inputs-group
            display: flex
            align-items: center
            position: relative
        &__input-container
            padding-left: 10px
            position: relative
            &_height
                &:hover
                    .param__auto_height
                        opacity: 1 
            &::before
                content: ''
                display: block
                background-size: contain
                background-repeat: no-repeat
                position: absolute
                top: 50%
                transform: translateY(-50%)
                left: -5px
            &_pt::before
                background-image: url('../../assets/img/s-arrow-up.png')
                width: 7px
                height: 10px
            &_pb::before
                background-image: url('../../assets/img/s-arrow-down.png')
                width: 7px
                height: 10px
            &_pl::before
                background-image: url('../../assets/img/s-arrow-left.png')
                width: 10px
                height: 7px
            &_pr::before
                background-image: url('../../assets/img/s-arrow-right.png')
                width: 10px
                height: 7px
            &_height
                margin-left: 53px
                &::before
                    background-image: url('../../assets/img/s-arrow-vertical.png')
                    width: 7px
                    height: 14px
            &_width::before
                background-image: url('../../assets/img/s-arrow-horizontal.png')
                width: 14px
                height: 7px
        &__input
            background: transparent
            border: none
            border-bottom: 1px solid $pink
            line-height: 18px
            font-family: $lato
            width: 50px
            text-align: right
            &-box_color
                background-image: url('../../assets/img/color.png')
                background-repeat: no-repeat
                background-size: contain
                position: absolute
                left: -5px
                width: 13px 
                height: 14px
                z-index: 9
                &:hover
                    cursor: pointer
            &_hidden
                display: none
            &:hover,
            &:focus
                border-color: $red
        &__auto_height
            display: block
            opacity: 0
            transition: .1s linear
            position: absolute
            width: 40px
            border: none
            padding-top: 10px
            padding-bottom: 2px
            border-bottom: 1px solid $pink
            background: none
            text-align: right
            &:hover
                cursor: pointer
                border-color: $red
        &__px
            font-size: 11px
            margin-left: 1px
        &__lock
            width: 16px
            height: 12px
            margin: 0 20px
            background-image: url('../../assets/img/margin.png')
            background-size: contain
            background-repeat: no-repeat
            &:hover
                cursor: pointer
            &.active
                background-image: url('../../assets/img/margin-bg.png')
</style>