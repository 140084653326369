<template lang="html">
  <div class="banner-modal">
    <transition name="message" mode="out-in">
      <div class="banner-modal__error" v-if="error">{{ error }}</div>
      <div class="banner-modal__success" v-else-if="success">{{ success }}</div>
    </transition>

    <h2 class="banner-modal__title">{{ modalTitle }}</h2>

    <form 
      class="banner-modal__form"
      @submit.prevent="onModalAction"
    >
      <div class="input-container">
        <label class="input-title" for="release_version">Release version:</label>
        <input
          class="input-text"
          type="text"
          id="release_version"
          v-model="bannerData.release_version"
          placeholder="Enter release version"
        >
      </div>
      <div class="input-container" v-if="showMinClientVersion">
        <label class="input-title" for="min_client_version">Min release version:</label>
        <input
          class="input-text"
          type="text"
          id="min_client_version"
          v-model="bannerData.min_client_version"
          placeholder="Enter mininal release version"
        >
      </div>
      <div class="input-container">
        <label class="input-title" for="release_date">
          Release date:
        </label>
        <input
          class="input-date"
          type="date"
          id="release_date"
          v-model="bannerData.release_date"
        >
      </div>
      <div class="input-container">
        <label class="input-title" for="release_comment">Comment:</label>
        <input
          class="input-text"
          id="release_comment"
          type="text"
          v-model="bannerData.comment"
          placeholder="Enter comment (optional)"
        >
      </div>
      <div class="loader-container" v-if="loader">
        <img :src="require('../../assets/img/autosave_saving.gif')">
      </div>
      <transition name="buttons-show">
        <div class="buttons-container" v-if="!loader">
          <button
            class="input-button input-button_delete"
            type="button"
            @click="onDeleteBanner"
            v-if="mode === 'edit'"
          >Delete banner</button>
          <button
            class="input-button"
            type="button"
            @click="$emit('close')"
          >Cancel</button>
          <button
            class="input-button"
            type="submit"
          >{{ modalButtonTitle }}</button>
        </div>
      </transition>
    </form>
    <!-- End of new banner -->
  </div>
</template>

<script>
import ModalMessageMixin from '@/mixins/ModalMessageMixin';

export default {
  props: ['appId', 'bannerToEdit', 'bannerToDuplicate'],
  mixins: [ModalMessageMixin],
  data: () => ({
    mode: 'add',
    allowAction: true,

    modalTitle: '',
    modalButtonTitle: '',
    onModalAction: () => {},

    showMinClientVersion: false,
    bannerData: {
      release_version: '',
      release_date: '',
      min_client_version: '',
      comment: ''
    },

    loader: false,
    sortedBanners: []
  }),
  mounted() {
    if (Object.entries(this.bannerToEdit).length) this.mode = 'edit';
    else if (Object.entries(this.bannerToDuplicate).length) this.mode = 'duplicate'; 
  },
  computed: {
    banners() {
      return this.$store.getters.banners;
    },
    autoSaveTimer() {
      return this.$store.getters.autoSaveTimer;
    }
  },
  methods: {
    async editSortIndex(old_sort_index, new_release_version) {
      let new_sort_index = old_sort_index;

      let i = new_sort_index;
      let vector = 1;
      function changeI(vector) {
        i = i + vector;
      }
      while (i >= 0 && i <= this.sortedBanners.length - 1) {
        if (this.sortedBanners[i+1] && 
            await this.$store.dispatch('compareBannersByReleaseVersion', {
            a: this.sortedBanners[i+1].release_version, 
            b: new_release_version
          }) === 1) {
          // console.log(`comparing ${new_release_version} and ${this.sortedBanners[i+1].release_version} gives 1, go up`);
          // b
          // a
          // if a > b
          // swap up
          new_sort_index = new_sort_index + 1;
          const newBannerData = { sort_index: new_sort_index - 1};
          const bannerId      = this.sortedBanners[i+1].id;
          await this.$store.dispatch('editBanner', {newBannerData, bannerId});
        } else if (this.sortedBanners[i-1] && 
                  await this.$store.dispatch('compareBannersByReleaseVersion', {
                    a: new_release_version, 
                    b: this.sortedBanners[i-1].release_version
                  }) === 1) {
          // console.log(`comparing ${this.sortedBanners[i-1].release_version} and ${new_release_version} gives 1, go down`);
          // a
          // b
          // if a < b
          // swap down
          new_sort_index = new_sort_index - 1;
          const newBannerData = { sort_index: new_sort_index + 1};
          const bannerId      = this.sortedBanners[i-1].id;
          await this.$store.dispatch('editBanner', {newBannerData, bannerId});
          vector = -1;
        } else {
          break;
        }
        changeI(vector);
      }
      
      return new_sort_index;
    },

    async onAddNewBanner() {
      if (this.validateForm() && this.allowAction) {
        this.loader = true;
        this.allowAction = false;
        this.$store.commit('updateActiveBanner', {});

        const bannerStyles = this.$store.getters['templates'].find(t => t.type === "banner");
        const sort_index   = await this.$store.dispatch('defineSortIndex', { 
          sorted_banners: this.sortedBanners, release_version: this.bannerData.release_version });
          

        const newBannerData = {
          release_version:    this.bannerData.release_version,
          release_date:       this.bannerData.release_date,
          min_client_version: this.bannerData.min_client_version,
          comment:            this.bannerData.comment,
          app:                this.appId,
          fields:             [],
          styles:             {...bannerStyles.styles},
          sort_index
        };
        await this.$store.dispatch('addBanner', newBannerData);
        this.$store.commit('updateAutoSaveTimer', 0);
        this.$emit('close');
        this.loader = false;
      }
    },

    async onEditBanner() {
      if (this.validateForm() && this.allowAction) {
        this.allowAction = false;
        const new_sort_index  = await this.editSortIndex(this.bannerToEdit.sort_index, this.bannerData.release_version);
        const newBannerData = {
          release_version:    this.bannerData.release_version,
          release_date:       this.bannerData.release_date,
          comment:            this.bannerData.comment,
          min_client_version: this.bannerData.min_client_version,
          sort_index:         new_sort_index
        };

        const dateFields = this.$store.getters['currentBannerFields'].filter(f => f.type === 'date');

        for (let i = 0; i < dateFields.length; i++) {
          const updatedField = await this.$store.dispatch('updateField', { ...dateFields[i], value: newBannerData.release_date });
          this.$store.commit('updateCurrenBannerFieldByIndex', updatedField);
        }

        const bannerId = this.bannerToEdit.id;
        const editedBanner = await this.$store.dispatch('editBanner', { newBannerData, bannerId });
        this.$store.commit('updateActiveBanner', editedBanner);
        this.$store.commit('updateAutoSaveTimer', 1);
        this.$emit('close');
      }
    },

    async onDuplicateBanner() {
      if (this.validateForm() && this.allowAction) {
        this.allowAction = false;
        this.loader = true;

        const sort_index = await this.$store.dispatch('defineSortIndex', { 
          sorted_banners: this.sortedBanners, release_version: this.bannerData.release_version });

        const bannerToDuplicate = {
          release_version: this.bannerData.release_version,
          release_date: this.bannerData.release_date,
          min_client_version: this.bannerData.release_version === '0' ? this.bannerData.min_client_version : '',
          comment: this.bannerData.comment,
          styles: this.bannerToDuplicate.styles,
          sort_index,
          export_id: null,
          app: this.appId
        }
        const fieldsToDuplicate = this.$store.getters['currentBannerFields'].map(
          f => ({ ...f, value: f.type === 'date' ? bannerToDuplicate.release_date : f.value })
        );

        const bannerDuplicate = await this.$store.dispatch('duplicateBanner', { bannerToDuplicate, fieldsToDuplicate });

        await this.$store.dispatch('setActiveBanner', bannerDuplicate);
        this.$store.commit('updateAutoSaveTimer', 1);

        this.loader = false;
        this.$emit('close');
      }
    },

    async onDeleteBanner() {
      this.loader = true;
      this.$store.commit('updateActiveBanner', {});
      const removed_sort_index = this.bannerToEdit.sort_index;
      await this.$store.dispatch('deleteBanner', this.bannerToEdit);
      // Update sort_index for next placed banners
      await this.$store.dispatch('refreshBannersSortIndex', removed_sort_index);
      this.loader = false;
      this.$emit('close');
    },

    validateForm() {
      try {
        const release_version_expression = /^[0-9]{1,6}((\.[0-9]{1,6})?){0,3}$/g;
        const release_version_regex = new RegExp(release_version_expression);
      
        if (!this.bannerData.release_version) {
          throw 'Release version is required ';
        } else if (this.bannerData.release_version == 0) {
          if (!this.bannerData.min_client_version) {
            this.bannerData.min_client_version = '0';
          } else if (!this.bannerData.min_client_version.match(release_version_regex)) {
            throw 'Invalid minimum release version format ';
          }
        } else if (!this.bannerData.release_version.match(release_version_regex)) {
          throw 'Invalid release version format ';
        } else if (!this.bannerData.release_date) {
          throw 'Release date is required'
        } else {
          // Check for unique value in release version field
          // on banner ADD or DUPLICATE
          if (this.mode === 'add' || this.mode === 'duplicate') {
            this.sortedBanners.forEach(banner => {
              if (banner.release_version == this.bannerData.release_version) {
                throw "Release version must contain a unique value";
              }
            });
          }
          // Check for unique value in release version field
          // on banner EDIT
          else if (this.mode === 'edit') {
            this.sortedBanners.forEach(banner => {
              if (banner.release_version == this.bannerToEdit.release_version) {
                return;
              }
              else if (banner.release_version == this.bannerData.release_version) {
                throw "Release version must contain a unique value";
              }
            });
          }
        }
        return true;
      } catch (e) {
        this.setMessage('error', e);
        return false;
      }
    }
  },
  watch: {
    mode: {
      handler(mode) {
        switch (mode) {
          case 'add': 
            this.modalTitle = 'New banner';
            this.modalButtonTitle = 'Add';
            this.onModalAction = this.onAddNewBanner;
            break;
          case 'edit': 
            this.modalTitle = 'Release options';
            this.modalButtonTitle = 'Save';
            this.onModalAction = this.onEditBanner;
            this.bannerData = Object.assign({}, this.bannerToEdit);
            break;
          case 'duplicate': 
            this.modalTitle = 'Duplicate banner';
            this.modalButtonTitle = 'Duplicate';
            this.onModalAction = this.onDuplicateBanner;
            break;
        }
      },
      immediate: true
    },
    'bannerData.release_version': {
      handler(value) {
        if (value == 0 && value !== "") {
          this.showMinClientVersion = true;
        } else {
          this.showMinClientVersion = false;
        }
      },
      immediate: true,
      deep: true
    },
    autoSaveTimer: {
      handler(value) {
        if (value !== 'Done') {
          this.loader = true;
        } else {
          this.loader = false;
        }
      },
      immediate: true
    },
    banners: {
      handler(banners) {
        this.sortedBanners = [...banners].sort((a, b) => a.sort_index - b.sort_index);
      },
      immediate: true
    }
  }
}
</script>

<style lang="sass" scoped>
@import '../../assets/mixins/mixins'

.banner-modal
  @include modal
  .input-container
    &:first-child
      margin-bottom: 20px
  &__form
    .input-container
      .input-title
        width: 145px
      .input-date
        &::-webkit-inner-spin-button 
          -webkit-appearance: none
          display: none
        &::-webkit-calendar-picker-indicator
          background-image: url('../../assets/img/calendar.png')
          &:hover
            cursor: pointer
            background-image: url('../../assets/img/calendar_hover.png')

.message-enter, .message-leave-to
  opacity: 0

.message-enter-active, .message-leave-active
  transition: all .2s ease

.buttons-show-enter, .buttons-show-leave-to
  opacity: 0

.buttons-show-enter-active, .buttons-show-leave-active
  transition: all .2s ease

@media screen and (max-width: 680px)
  .banner-modal
    @include modal-max-680
  
</style>
