<template lang="html">
  <!-- Date -->
  <div 
    class="input-container" 
    v-if="field.type === 'date'"
  >
    <input
      class="input input-date"
      type="date"
      v-model="field.value"
      required="required"
    >
  </div>
  <!-- Header -->
  <div
    class="input-container"
    v-else-if="
      field.type === 'headerh1' 
      || field.type === 'headerh2' 
      || field.type === 'headerh1group' 
      || field.type === 'headerh2group'"
  >
    <input
      class="input input-text"
      type="text"
      v-model="field.value"
      ref="input_header"
      :placeholder="`Header ${field.type === 'headerh1' || field.type === 'headerh1group' ? 'H1' : 'H2'}`"
    >
  </div>
  <!-- Text -->
  <div
    class="input-container"
    v-else-if="field.type === 'text' || field.type === 'textgroup'"
  >
    <textarea
      class="input input-textarea"
      ref="input_textarea"
      rows="1"
      placeholder="Text"
      v-model="field.value"
    ></textarea>
  </div>
  <!-- Image -->
  <div 
    class="input-container input-container_image"
    v-else-if="field.type === 'image' || field.type === 'imagegroup'"
  >
    <img
      class="image"
      v-if="field.image"
      :src="createImageURL(field.image)"
    >
    <button 
      type="button"
      class="input-button"
      @click="$refs['input_image'].click()"
    >
      {{ field.image ? 'Change' : 'Add' }}
    </button>
    <input
      class="input-image"
      type="file"
      accept="image/*"
      @change="onImageChange"
      ref="input_image"
    >
  </div>
  <!-- Video (Youtube) -->
  <div
    class="input-container"
    v-else-if="field.type === 'youtube' || field.type === 'youtubegroup'"
  >
    <input
      class="input input_video"
      type="text"
      placeholder="Enter youtube link and press 'Enter'"
      ref="input_video"
      :value="field.value"
      @change="setYoutubeUrl"
    >
  </div>
  <!-- Bulletpoints -->
  <div
    class="input-container"
    v-else-if="field.type === 'bulletpoints' || field.type === 'bulletpointsgroup'"
  >
    <ul class="input-bp__list">
      <li
        class="input-bp__line"
        v-for="(line, index) in bpLines"
        :key="index"
      >
        <span class="input-bp__bulletpoint"></span>
        <input
          class="input-bp__input"
          type="text"
          v-model="bpLines[index]"
          @keydown="bpHandler"
          :ref="`line-${index}`"
          :name="`line-${index}`"
        >
      </li>
    </ul>
  </div>
  <!-- Combo -->
  <div 
    class="input-container input-container_combo"
    v-else-if="field.type === 'combo'"
  >
    <img
      class="image_combo"
      :src="field.image ? createImageURL(field.image) : strapiHost + field.value"
    >
    <span class="combo-value">{{ getComboValue(field.id) }}</span>
  </div>
  <!-- Feedback -->
  <div
    class="input-container input-container_feedback"
    v-else-if="field.type === 'feedback'"
  >
    {{ 'Good: ' + feedbackStat.good + ', Neutral: ' +  feedbackStat.neutral + ', Bad: ' + feedbackStat.bad }}
  </div>
</template>

<script>
export default {
  props: ['field'],
  data: () => ({
    bpLines: [''],
    fieldStyles: '',

    textHiddenValue: '',
    groupMode: false
  }),
  methods: {
    // IMAGE
    createImageURL(image) {
      if ('host' in image) {
        return image.host;
      } else if ('url' in image) {
        return this.ENV_STRAPI_HOST + image.url;
      } else {
        return URL.createObjectURL(image);
      }
    },
    async onImageChange(e) {
      const targetImage = e.target.files[0];

      if (targetImage.size > 1048576) {
        this.$refs['input_image'].value = "";
        alert('Maximum image size - 1MB');
        return;
      }
      const imageToDelete = this.field.image;
      this.field.image = targetImage;

      if (imageToDelete && ('id' in imageToDelete)) {
        await this.$store.dispatch('deleteImage', imageToDelete.id);
      }
      this.$store.commit('updateAutoSaveTimer', 3);
    },
    // VIDEO
    setYoutubeUrl() {
      this.transformVideoLink(this.$refs['input_video'].value);
    },
    transformVideoLink(link) {
      if (link.startsWith('https://youtu.be/')) {
        const videoId = link.slice(17);
        link = 'https://www.youtube.com/embed/' + videoId;
        this.field.value = link;
      }
      if (link.includes('youtube.com/embed/')) {
        this.field.value = link;
      } else if (link.includes('youtube.com/watch?v=')) {
        // from link?v=id get id
        const videoQuery = link.split('?')[1];
        const videoId = videoQuery.split('&')
                        .find(query => query.startsWith('v='))
                        .slice('2');

        link = 'https://www.youtube.com/embed/' + videoId;
        this.field.value = link;
      } else if (link !== ""){
        this.field.value = "";
        this.$refs['input_video'].value = "";
        alert('Incorrect YouTube link format ');
      }
    },
    // BULLETPOINTS
    bpHandler(e) {
      // ENTER
      if (e.keyCode === 13) {
        // get cuurent line index
        const lineIndex = e.target.attributes.name.value.split('-')[1];
        // next line index
        const nextLineIndex = +lineIndex + 1;
        // add new line
        const bpLinesCopy = this.bpLines;
        bpLinesCopy.splice(nextLineIndex, 0, '');
        this.bpLines = bpLinesCopy;
        // focus last line
        this.$nextTick(() => {
          this.$refs[`line-${nextLineIndex}`][0].focus();
        });
      }
      // BACKSPACE
      else if (e.keyCode === 8 && e.target.value === '' && this.bpLines.length > 1) {
        // prevent backspace navigation
        e.preventDefault();
        // get current line index
        const lineIndex = e.target.attributes.name.value.split('-')[1];
        // delete line
        this.bpLines.splice(lineIndex, 1);
        // focus previous line
        this.$nextTick(() => {
          if (lineIndex == 0 && this.bpLines.length > 0) {
            this.$refs['line-0'][0].focus();
          } else {
            const prevLineIndex = +lineIndex - 1;
            this.$refs[`line-${prevLineIndex}`][0].focus();
          }
        });
      }
      // ARROW UP
      else if (e.keyCode === 38) {
        // get current line index
        const lineIndex = e.target.attributes.name.value.split('-')[1];
        // focus prev line
        if (lineIndex > 0) {
          const prevLineIndex = +lineIndex - 1;
          this.$refs[`line-${prevLineIndex}`][0].focus();
        }
      }
      // ARROW DOWN
      else if (e.keyCode === 40) {
        // get current line index
        const lineIndex = e.target.attributes.name.value.split('-')[1];
        // focus next line
        if (lineIndex < this.bpLines.length - 1) {
          const nextLineIndex = +lineIndex + 1;
          this.$refs[`line-${nextLineIndex}`][0].focus();
        }
      }
    },
    getComboValue(listFieldId) {
      const relatedH2Field = this.currentBannerFields.find(f => 
        f.related_group_id && f.related_group_id === listFieldId && f.type === 'headerh2group');
      if (relatedH2Field && relatedH2Field.value) {
        return relatedH2Field.value;
      } else {
        return 'Combo';
      }
    }
  },
  computed: {
    fieldIndex() {
      return this.field.field_index;
    },
    fieldValue() {
      return this.field.value;
    },
    strapiHost() {
      return this.$store.getters.STRAPI_HOST;
    },
    currentBannerFields() {
      return this.$store.getters['currentBannerFields'];
    },
    feedbackStat() {
      return this.$store.getters['feedbackStat'];
    }
  },
  watch: {
    bpLines(bpLs) {
      if (bpLs) {
        this.field.value = bpLs.join('/bl');
      }
    },
    field(f) {
      if (f.type === 'bulletpoints') {
        this.bpLines = f.value.split('/bl');
      }
      this.fieldStyles = f.styles;
    },
    youtubeLink() {
      this.setUrl();
    },
    'field.value': {
      handler(value) {
        if (value.indexOf('<iframe') !== -1) {
          const updatedField = { ...this.field, value: value.replace('<iframe', '') }
          this.$store.commit('updateCurrenBannerFieldByIndex', updatedField);
        } 
        
        if (value.indexOf('<script') !== -1) {
          const updatedField = { ...this.field, value: value.replace('<script', '') }
          this.$store.commit('updateCurrenBannerFieldByIndex', updatedField);
        } 
      
        if (value.indexOf('javascript:') !== -1) {
          const updatedField = { ...this.field, value: value.replace('javascript:', '') }
          this.$store.commit('updateCurrenBannerFieldByIndex', updatedField);
        } 

        if (value.indexOf('onmouseover=') !== -1) {
          const updatedField = { ...this.field, value: value.replace('onmouseover=', '') }
          this.$store.commit('updateCurrenBannerFieldByIndex', updatedField);
        }

        if (value.indexOf('onerror=') !== -1) {
          const updatedField = { ...this.field, value: value.replace('onmouseover=', '') }
          this.$store.commit('updateCurrenBannerFieldByIndex', updatedField);
        } 

        this.$store.commit('updateAutoSaveTimer', 3);
      }
    },
    // 'field.image': {
    //   handler() {
    //     console.log('field.image');
    //     this.$store.commit('updateAutoSaveTimer', 3);
    //   }
    // }
  },
  mounted() {
    // Autofocus on add new field
    const textarea = this.$refs['input_textarea'];

    if (('autofocus' in this.field) && this.field.autofocus) {
      if (this.field.type === 'headerh1' 
          || this.field.type === 'headerh2'
          || this.field.type === 'headerh1group'
          || this.field.type === 'headerh2group') {
        this.$refs['input_header'].focus();
      } else if (this.field.type === 'text' || this.field.type === 'textgroup') {
        this.$refs['input_textarea'].focus();
      } else if (this.field.type === 'image' || this.field.type === 'imagegroup') {
        this.$refs['input_image'].click();   
      } else if (this.field.type === 'youtube' || this.field.type === 'youtubegroup') {
        this.$refs['input_video'].focus();
      } else if (this.field.type === 'bulletpoints' || this.field.type === 'bulletpointsgroup') {
        this.$refs['line-0'][0].focus();
      } 
      this.field.autofocus = false;
    } 

    if (this.field.type === 'text' || this.field.type === 'textgroup') {
      this.$nextTick(() => {
        this.$autosize(textarea);
      });
    }

    if (this.field.type === 'bulletpoints') {
      this.bpLines = this.field.value.split('/bl');
    } else if (this.field.type === 'video') {
      this.$refs['input_video'].value = this.field.value;
    }
  }
}
</script>

<style lang="sass" scoped>
@import '../assets/variables/variables'

.input-container
  flex: 1
  &_image,
  &_combo
    display: flex
    align-items: center
    position: relative
  &_feedback
    font-size: 14px
  .input
    font-family: $lato
    color: $grey-text
    font-size: 14px 
    line-height: 1.3em
    background-color: transparent
    border: none
    border-bottom: 1px solid transparent
    transition: .1s linear
    width: 100%
    padding: 5px 0
    &:hover
      border-color: $pink
    &:focus
      border-color: $red
  .input-date
    &::-webkit-inner-spin-button 
      -webkit-appearance: none
      display: none
    &::-webkit-calendar-picker-indicator
      background-image: url('../assets/img/calendar.png')
      &:hover
        cursor: pointer
        background-image: url('../assets/img/calendar_hover.png')
  .input-textarea
    resize: none
  .input-image
    display: none
  .image
    height: 29px
    width: 100px
    margin-right: 15px
    object-fit: cover
  .image_combo
    height: 40px
    position: absolute
    left: 0
    max-width: 45px
    object-fit: contain
  .combo-value 
    margin-left: 55px
    font-family: $lato
    color: $grey-text
    font-size: 14px 
    line-height: 1.3em
  .input-button
    border: none
    background: none
    color: $pink
    font-family: $lato
    text-transform: uppercase
    transition: .1s linear
    font-size: 14px
    &:hover
      cursor: pointer 
      color: $red
  .input-bp
    &__list
      list-style: none
      width: 100%
      &:hover
        .input-bp__line
          .input-bp__input
            border-color: $pink 
    &__line
      position: relative
      &:last-child
        .input-bp__input
          padding-bottom: 5px
          border-bottom: 1px solid transparent
          transition: border-color .1s linear
    &__bulletpoint
      display: block
      width: 4px
      height: 4px
      background-color: $grey-text
      border-radius: 100%
      position: absolute
      top: 9px
    &__input
      background: transparent
      border: none
      width: 100%
      font-family: $lato
      font-size: 14px
      color: $grey-text
      padding-left: 10px
      &:focus
        border-color: $red !important

@media screen and (max-width: 680px)
  .input-container
    .input-text,
    .input-textarea,
    .input-date
      border-radius: 0
      font-size: 14px

</style>
