<template lang="html">
  <div class="bannerlist">
    <!-- This container disallows to use sidebar while austosaving -->
    <div
      v-if="autoSaveTimer !== 'Done'"
      class="autosave-wait"
    >
    </div>

    <div class="bannerlist__header">
      <button
        class="new-banner-button"
        type="button"
        @click="onNewBanner"
      >New banner</button>
    </div>

    <div class="bannerlist__main">
      <div class="list">
        <div
          class="banner"
          :class="{'active': banner.id === activeBanner.id}"
          v-for="(banner, index) in sortedBanners"
          :key="index"
          @click="onSetActiveBanner(banner)"
        >
          <div class="banner__body">
            <!-- Banner data  -->
            <div class="release-container">
              <div class="release-version">
                {{banner.release_version}}
              </div>
              <div class="release-date">
                {{ banner.release_date | date() }}
              </div>
            </div>
            <div class="comment">
              {{ banner.comment }}
            </div>
          </div>
          <div class="banner__footer" v-if="banner.id === activeBanner.id">
            <div class="buttons-container">
              <button 
                class="button button_edit"
                @click.stop="$emit('bannerEdit')"
                v-tooltip="'Release options'"
              ></button>
              <button 
                class="button button_appearance"
                @click.stop="$emit('bannerOptions')"
                v-tooltip="'Banner appearance'"
              ></button>
              <button 
                class="button button_duplicate" 
                @click.stop="$emit('bannerDuplicate')"
                v-tooltip="'Duplicate banner'"
              ></button>
              <button 
                class="button button_export" 
                @click.stop="$emit('bannerExport')"
                v-tooltip="'Export banner'"
              ></button>
            </div>
            <div class="views-container">
              <span class="title">Views: </span>
              <span class="views-counter">{{ viewsCounter }}</span>
            </div>
          </div>
        <!-- End of banner -->
        </div>
      <!-- End of list -->
      </div>
    <!-- End of main -->
    </div>
  <!-- End of bannerlist -->
  </div>
</template>

<script>
export default {
  data: () => ({
    sortedBanners: []
  }),
  methods: {
    onNewBanner() {
      const stonlyWidgetCloseButton = document.querySelectorAll('[aria-label="Close Stonly widget"]');
      if (stonlyWidgetCloseButton.length) {
        for (let i = 0; i < stonlyWidgetCloseButton.length; i++) {
          stonlyWidgetCloseButton[i].click();
        }
      }
      this.$emit('newBanner');
    },
    async onSetActiveBanner(banner) {
      this.$emit('setActiveBanner');
      await this.$store.dispatch('setActiveBanner', banner);
    }
  },
  computed: {
    activeBanner() {
      return this.$store.getters['activeBanner'];
    },
    autoSaveTimer() {
      return this.$store.getters['autoSaveTimer'];
    },
    banners() {
      return this.$store.getters.banners;
    },
    viewsCounter() {
      return this.$store.getters['viewsCounter'];
    }
  },
  watch: {
    banners(banners) {
      this.sortedBanners = [...banners].sort((a, b) => b.sort_index - a.sort_index);
    }
  }
}
</script>

<style lang="sass" scoped>
@import '../assets/variables/variables'

@mixin block
  background-color: #fff
  border: 1px solid $grey-title
  transition: .1s linear
  box-shadow: 14.420px 14.420px 20px 0px rgba(93, 93, 93, 0.15)
  &:hover
    cursor: pointer
    border-color: $red

::-webkit-scrollbar
  width: 7px
  background: #fff
  border: 1px solid #e8e8e8
  border-radius: 5px
::-webkit-scrollbar-thumb
  border-radius: 5px
  background-color: $pink
    
.bannerlist
  width: 100%
  height: 100%
  max-height: 100%
  overflow-y: scroll
  scrollbar-width: thin
  scrollbar-color: $pink #f0f0f0
  position: relative
  &:hover
    .autosave-wait
      opacity: 1
  .autosave-wait
    position: absolute
    height: 100%
    width: 240px
    top: 0
    left: 0
    background-color: rgba(#fff, 0.5)
    z-index: 99
    opacity: 0
    transition: .1s linear
    .loader
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
  &__header
    @include block
    width: 240px
    margin-bottom: 10px
    .new-banner-button
      width: 100%
      height: 100%
      background: transparent
      border: none
      height: 60px
      padding-left: 8px
      text-transform: uppercase
      font-size: 14px
      font-family: $lato
      color: $pink
      position: relative
      transition: .1s linear
      &::before
        content: ''
        display: block
        width: 12px
        height: 12px
        position: absolute
        background-image: url('../assets/img/plus.png')
        background-repeat: no-repeat
        background-size: contain
        top: 50%
        transform: translateY(-50%)
        left: 52px
      &:hover
        cursor: pointer
        color: $red
        &::before
          background-image: url('../assets/img/plus_hover.png')
  &__main
    .list
      width: 240px
      .banner
        @include block
        font-family: $lato
        color: $grey-text
        margin-bottom: 10px
        &.active
          background-color: $pink-light
          border-color: $grey-text
        &__body
          padding: 20px
          .release-container
            display: flex
            justify-content: space-between
            font-weight: 900
            font-size: 13px
          // .release-version
          // .release-date
          .comment
            margin-top: 8px
            font-size: 14px
        &__footer
          border-top: 1px solid $grey-text
          padding: 15px 20px
          font-size: 14px
          display: flex
          line-height: 9px
          .buttons-container
            display: flex
            .button
              background: none
              border: none
              width: 16px
              height: 16px
              background-repeat: no-repeat
              background-size: contain
              margin-right: 12px
              &:hover
                cursor: pointer
              &_edit
                background-image: url('../assets/img/edit.png')
                &:hover
                  background-image: url('../assets/img/edit_hover.png')
              &_appearance
                background-image: url('../assets/img/appearance.png')
                &:hover
                  background-image: url('../assets/img/appearance_hover.png')
              &_duplicate
                background-image: url('../assets/img/duplicate.png')
                &:hover
                  background-image: url('../assets/img/duplicate_hover.png')
              &_export
                background-image: url('../assets/img/export.png')
                &:hover
                  background-image: url('../assets/img/export_hover.png')
          .views-container
            display: flex
            width: 50%
            justify-content: flex-end
            align-items: flex-end
            .title
              font-size: 12px
              margin-right: 5px
            .views-counter
              font-weight: 900
              font-size: 13px
              line-height: 10px

@media screen and (max-width: 1400px)
  .bannerlist
    &__header
      width: 220px
    &__main
      .list
        width: 220px

@media screen and (max-width: 1240px)
  .bannerlist
    &__header
      width: calc(100% - 30px)
    &__main
      .list
        width: calc(100% - 30px)

@media screen and (max-width: 680px)
  .bannerlist
    &__header
      width: 100%
      .new-banner-button
        &::before
          left: 50px
    &__main
      .list
        width: 100%
</style>
