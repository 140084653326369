<template>
  <div class="app-page">
    <Header :user="user"/>

    <main class="app-page__main">
      <transition name="cover" mode="in-out">
        <div 
          class="light-cover"
          v-if="showMobileBannerlist"
        ></div>
      </transition>

      <button
        class="bannerlist-mobile-button"
        :class="{'active': showMobileBannerlist}"
        @click="showMobileBannerlist = !showMobileBannerlist"
      ></button>

      <div 
        class="bannerlist-container"
        :class="{'hidden': !showMobileBannerlist}"
      >
        <Bannerlist
          @newBanner="onNewBanner"
          @setActiveBanner="showMobileBannerlist = false"
          @bannerEdit="onBannerEdit"
          @bannerOptions="showBannerOptionsModal = true"
          @bannerDuplicate="onBannerDuplicate"
          @bannerExport="onBannerExport"
        />
      </div>
      <transition name="editor-show">
        <div 
          class="editor-container" 
          id="editor_container"
          v-if="Object.entries(activeBanner).length > 1"
          @mouseleave="stopDragging = !stopDragging"
        >     
          <FieldsEditor 
            :activeBanner="activeBanner"
            :stopDragging="stopDragging"
            @showFeedbackModal="onShowFeedbackModal"
          />
        </div>
      </transition>
      <transition name="editor-show">
        <div 
          class="preview-container"
          v-if="Object.entries(activeBanner).length > 1"
        >
          <BannerPreview
            :activeBanner="activeBanner"
          />
        </div>
      </transition>

      <a 
        class="open-preview-link"
        v-if="Object.entries(activeBanner).length > 1"
        :href="'https://appversion.info/getbanner.php?app_uid=' + currentApp.uid + '&release_version=' + activeBanner.release_version"
        target="_blank"
      >Open preview</a>
    </main>

    <transition name="cover" mode="in-out">
      <div 
        class="app-page__dark-cover" 
        v-if="showBannerModal || showAppModal || showBannerExportModal || showFeedbackModal || showBannerOptionsModal"
      ></div>
    </transition>

    <transition name="aside" mode="in-out">
      <template v-if="showBannerModal">
        <aside class="app-page__aside">
          <BannerModal
            @close="showBannerModal = false"
            :appId="currentApp.id"
            :bannerToEdit="bannerToEdit"
            :bannerToDuplicate="bannerToDuplicate"
          />
        </aside>
      </template>
      <template v-if="showAppModal">
        <aside class="app-page__aside">
          <AppModal
            @close="showAppModal = false"
            :app="currentApp"
            :currentTeamId="currentTeam.id"
          />
        </aside>
      </template>
      <template v-if="showBannerExportModal">
        <aside class="app-page__aside">
          <BannerExportModal
            :currentApp="currentApp"
            :banner="bannerToExport"
            @close="showBannerExportModal = false"
          />
        </aside>
      </template>
      <template v-if="showFeedbackModal">
        <aside class="app-page__aside">
          <FeedbackModal
            @close="onCloseFeedbackModal"
            :activeField="activeField"
          />
        </aside>
      </template>
      <template v-if="showBannerOptionsModal">
        <aside class="app-page__aside">
          <BannerOptionsModal
            @close="showBannerOptionsModal = false"
            :activeBanner="activeBanner"
          />
        </aside>
      </template>
    </transition>
    <!-- End of app-page -->
  </div>
</template>

<script type="text/javascript">
import Header from '@/components/Header';
import Bannerlist from '@/components/Bannerlist';
import BannerModal from '@/components/modals/BannerModal';
import AppModal from '@/components/modals/AppModal';
import FieldsEditor from '@/components/FieldsEditor';
import BannerPreview from '@/components/BannerPreview';
import BannerExportModal from '@/components/modals/BannerExportModal';
import FeedbackModal from '@/components/modals/FeedbackModal';
import BannerOptionsModal from '@/components/modals/BannerOptionsModal';
// import DeleteConfirmModal from '@/components/modals/DeleteConfirmModal';

export default {
  components: {
    Header, 
    Bannerlist, 
    BannerModal, 
    AppModal,
    FieldsEditor, 
    BannerPreview,
    BannerExportModal,
    FeedbackModal,
    BannerOptionsModal,
    // DeleteConfirmModal, 
  },
  data: () => ({
    showAppModal: false,

    showBannerModal: false,
    bannerToEdit: {},
    bannerToDuplicate: {},

    showBannerDeleteModal: false,
    bannerToDelete: {},

    showBannerExportModal: false,
    bannerToExport: {},

    timerTimeout: '',

    stopDragging: false,

    showMobileBannerlist: true,

    showFeedbackModal: false,
    activeField: null,

    showBannerOptionsModal: false
  }),
  methods: {
    async onCloseAllModals() {
      await this.$store.dispatch('fetchApp', this.$route.params.id)
      this.showAppModal = false;
      this.showBannerModal = false;
      this.showBannerExportModal = false;
    },
    // BANNER ADD, EDIT
    onNewBanner() {
      this.bannerToEdit = {};
      this.bannerToDuplicate = {};
      this.showBannerModal = true;
      this.showMobileBannerlist = false;
    },
    onBannerEdit() {
      this.bannerToEdit = this.activeBanner;
      this.bannerToDuplicate = {};
      this.showBannerModal = true;
    },
    onBannerDuplicate() {
      this.bannerToEdit = {};
      this.bannerToDuplicate = this.activeBanner;
      this.showBannerModal = true;
    },
    onBannerExport() {
      this.bannerToExport = this.activeBanner;
      this.showBannerExportModal = true;
    },

    // DELETE
    onBannerDelete(banner) {
      this.onBannerModalClose();
      this.bannerToDelete = banner;
      this.showBannerDeleteModal = true;
    },
    onBannerDeleteModalClose() {
      this.bannerToDelete = {};
      this.showBannerDeleteModal = false;
    },
    // EXPORT
    onBannerExportModalCloseAndAutosave() {
      this.bannerToExport = {};
      this.showBannerExportModal = false;
      this.$store.commit('updateAutoSaveTimer', 3);
    },
    // FEEDBACK MODAL
    onShowFeedbackModal({ activeField }) {
      this.activeField = Object.assign({}, activeField);
      this.showFeedbackModal = true;
    },
    onCloseFeedbackModal(updatedActiveField) {
      if (updatedActiveField) {
        this.$store.commit('updateCurrenBannerFieldByIndex', updatedActiveField);
        this.$store.commit('updateAutoSaveTimer', 3);
      }
      this.$store.commit('updateActiveFieldIndex', this.activeField.field_index);
      this.showFeedbackModal = false;
      this.activeField = null;
    }
  },
  computed: {
    currentApp() {
      return this.$store.getters['currentApp'];
    },
    autoSaveTimer() {
      return this.$store.getters['autoSaveTimer'];
    },
    user() {
      return this.$store.getters['user'];
    },
    currentTeam() {
      return this.$store.getters['currentTeam'];
    },
    activeBanner() {
      return this.$store.getters['activeBanner'];
    }
  },
  watch: {
    '$route': {
      async handler() {
        try {
          const appId = this.$route.params['id'];
          await this.$store.dispatch('fetchApp', appId);
          if (this.$route.query.exported) {
            const showReleaseVersion = this.$route.query.exported;
            const activeBanner = this.$store.getters.banners.find(b => b.release_version === showReleaseVersion);
            if (activeBanner) {
              this.$store.dispatch('setActiveBanner', activeBanner);
            } else {
              throw ''
            }
          } else {
            throw ''
          }
        } catch (e) {
          this.$store.dispatch('setActiveBanner', {});
        }
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="sass" scoped>
@import '../assets/variables/variables'

::-webkit-scrollbar
  width: 7px
  background: #fff
  border: 1px solid #e8e8e8
  border-radius: 5px
::-webkit-scrollbar-thumb
  border-radius: 5px
  background-color: $pink

.app-page
  width: 1760px
  height: 100%
  margin: 0 auto
  
  &__main
    margin-top: 20px
    height: calc(100% - 156px - 20px)
    display: flex

    .bannerlist-mobile-button
      display: none
      position: fixed
      width: 50px
      top: 0
      left: 0
      height: 100vh
      z-index: 900
      border: none
      background-image: url('../assets/img/bullets.png')
      background-repeat: no-repeat
      background-size: 28px 28px
      background-position: 50%
      background-color: #fdfdfd
      &:hover,
      &.active
        cursor: pointer
        background-image: url('../assets/img/bullets_hover.png')

    .bannerlist-container
      width: 280px
      height: 100%
    .editor-container
      width: calc(50% - 140px)
      padding: 0 30px
      max-height: 100%
      overflow-y: scroll
      scrollbar-width: thin
      scrollbar-color: $pink #f0f0f0

    .preview-container
      width: calc(50% - 140px)
      max-height: 100%
      overflow-y: scroll
      scrollbar-width: thin
      scrollbar-color: $pink #f0f0f0
      position: relative

    .open-preview-link
      display: none
      border: 1px solid $pink
      width: 100%
      text-align: center
      color: $pink
      text-decoration: none
      font-family: $lato
      padding: 20px 0
      margin-bottom: 40px
      transition: .1s linear
      &:hover
        cursor: pointer
        color: $red
        border-color: $red
  &__aside
    width: 480px
    height: 100vh
    position: fixed
    top: 0
    right: 0
    overflow-y: auto
    box-shadow: -4px 0px 10px -6px #000
    z-index: 1100
    background-color: #fff
    padding: 176px 0 20px
    scrollbar-width: thin
    scrollbar-color: $pink #f0f0f0

  &__dark-cover
    width: 100vw
    height: 100vh
    overflow: hiddden
    position: fixed
    top: 0
    left: 0
    background-color: rgba(#000, 0.15)
    z-index: 1000

// Transitions
.cover-enter, .cover-leave-to 
  opacity: 0

.cover-enter-active, .cover-leave-active
  transition: all .3s linear 

.aside-enter, .aside-leave-to
  transform: translateX(100%)

.aside-enter-active, .aside-leave-active
  transition: all .3s ease

.editor-show-enter, .editor-show-leave-to 
  opacity: 0

.editor-show-enter-active, .editor-show-leave-active
  transition: all .2s linear

@media screen and (max-width: 1760px)
  .app-page 
    width: 1400px

@media screen and (max-width: 1400px)
  .app-page
    width: 1240px
    .bannerlist-container
      width: 240px
    .editor-container
      width: calc(50% - 120px)
      padding: 0 20px
    .preview-container
      width: calc(50% - 120px)

@media screen and (max-width: 1240px)
  .app-page
    width: 680px
    height: auto
    padding: 0 50px
    &__main
      position: relative
      flex-wrap: wrap
      .bannerlist-mobile-button
        display: block
      .light-cover
        position: absolute
        z-index: 9
        width: 100%
        height: 100%
        background-color: rgba(#fff, 0.9)
    .bannerlist-container
      width: 50%
      position: absolute
      left: 0
      z-index: 99
      transition: left .2s linear
      height: calc(100vh - 156px - 20px)
      &.hidden
        left: -100%
    .editor-container
      width: 100%
      padding: 0
      max-height: none
      .editor
        padding-bottom: 25px
    .preview-container
      transition: right .2s linear
      width: 100%
      margin-top: 25px
      max-height: none

@media screen and (max-width: 680px)
  .app-page
    width: 100%
    padding: 0 50px
    &__main
      margin-top: 10px
      .open-preview-link
        display: block
      .bannerlist-container
        width: 100%
        &.hidden
          left: -120%
      .editor-container
        .editor
          &__banner-panel
            .panel-header
              flex-wrap: wrap
              position: relative
              .panel-data-block
                margin-right: 18px
                .data
                  font-size: 13px
                &__buttons
                  position: absolute
                  right: -10px
                  top: -5px
                  display: flex
                  flex-direction: column
                  padding-left: 0
                  margin-right: 0
                  .panel-button
                    margin-right: 0
                    margin-bottom: 15px
            .panel-comment
              font-size: 14px
      .preview-container
        display: none
    &__aside
      padding-top: 40px
      width: 100%

</style>
