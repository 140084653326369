<template lang="html">
  <div class="banner-export">
    <transition name="message" mode="out-in">
      <div class="banner-export__error" v-if="error">{{ error }}</div>
      <div class="banner-export__success" v-else-if="success">{{ success }}</div>
    </transition>

    <h2 class="banner-export__title">Export banner</h2>

    <form class="banner-export__form" @submit.prevent="onExportBanner">
      <div 
        class="input-container" 
        @mouseover="showList = true" 
        @mouseleave="showList = false"
      >
        <label class="input-title">Destination channels:</label>
        <div class="input-text" :class="{'input-text_disabled': !destinationApps.length}">{{ destinationApps.length || 'Select channels' }}</div>
        <transition name="list" mode="in-out">
          <ul class="input-list entry-list" v-if="showList">
            <li 
              class="entry-container"
              v-for="(app, index) in appsList"
              :key="index"
              @click="onToggleCheckbox(app.id, index)"
              :style="{'display': app.id === currentApp.id ? 'none' : 'flex'}"
            >
              <input
                type="checkbox"
                class="input-checkbox"
                :ref="'checkbox-' + index"
              >              
              <span class="entry-name">{{ app.title }}</span>
            </li>
          </ul>
        </transition>
      </div>
      <div 
        class="input-container" 
        :class="{'light': showList}"
      >
        <label class="input-title" for="release_version">Release version:</label>
        <input 
          class="input-text"
          id="release_version"
          type="text"
          v-model="release_version"
          placeholder="Enter release version"
        >
      </div>
      <div 
        class="input-container" 
        :class="{'light': showList}" 
        v-if="showMinClientVersion"
      >
        <label class="input-title" for="min_client_version">Min client version:</label>
        <input 
          class="input-text"
          id="min_client_version"
          type="text"
          v-model="min_client_version"
          placeholder="Min client version"
        >
      </div>
      <div 
        class="input-container" 
        :class="{'light': showList}"
      >
        <label class="input-title" for="release_date">Release date:</label>
        <input
          class="input-date"
          id="release_date"
          type="date"
          v-model="release_date"
        >
      </div>
      <div 
        class="input-container" 
        :class="{'light': showList}"
      >
        <label class="input-title" for="comment">Comment:</label>
        <input
          class="input-text"
          id="comment"
          type="text"
          v-model="comment"
          placeholder="Enter release comment"
        >
      </div>
      <div class="loader-container" v-if="loader">
        <img :src="require('../../assets/img/autosave_saving.gif')">
      </div>
      <transition name="buttons-show">
        <div 
          class="buttons-container"
          :class="{'light': showList}"
          v-if="!loader"
        >
          <button
            class="input-button"
            type="button"
            @click="$emit('close')"
            >Cancel</button>
          <button
            class="input-button"
            type="submit"
            >Export</button>
        </div>
      </transition>
    </form>
    <!-- end of banner export -->
  </div>
</template>

<script>
import ModalMessageMixin from '@/mixins/ModalMessageMixin';

export default {
  props: ['banner', 'currentApp'],
  mixins: [ModalMessageMixin],
  data: () => ({
    showMinClientVersion: false,
    release_version: '',
    release_date: '',
    min_client_version: '',
    comment: '',
    destinationApps: [],
    styles: {},
    showList: false,
    loader: false,
    toggledAppsBanners: []
  }),
  methods: {
    async onToggleCheckbox(appId, appIndex) {
      this.loader = true;
      const findIndexResult = this.destinationApps.findIndex(app => app.id === appId) 
      if (~findIndexResult) {
          this.destinationApps.splice(findIndexResult, 1);
          this.toggledAppsBanners.splice(findIndexResult, 1);
          this.updateListSelects();
        } else {
          this.destinationApps.push({ ...this.appsList[appIndex], index: appIndex });
          this.updateListSelects();
          const toggledAppBanners = await this.$store.dispatch('getAppBanners', appId); 
          this.toggledAppsBanners.push(toggledAppBanners);
      }
      this.loader = false;
    },
    async onExportBanner() {
      this.loader = true;
      if (await this.validateForm()){

        const min_client_version = this.release_version == '0' ? this.min_client_version : '';
        const bannerToExport = {
          release_version: this.release_version,
          release_date:    this.release_date,
          min_client_version,
          comment:         this.comment,
          styles:          this.styles
        };
        const fieldsToExport = this.$store.getters['currentBannerFields'].map(
          f => ({ ...f, value: f.type === 'date' ? bannerToExport.release_date : f.value })
        );

        for (let i = 0; i < this.destinationApps.length; i++) {
          const destinationAppBanners = this.toggledAppsBanners[i].sort((a, b) => a.sort_index - b.sort_index);
          const sort_index = await this.$store.dispatch('defineSortIndex', {
                        sorted_banners: destinationAppBanners, release_version: this.release_version
                      });
          const destinationAppId = this.destinationApps[i].id;

          const newBanner = await this.$store.dispatch('exportBanner', { 
                              bannerToExport: { ...bannerToExport, sort_index }, fieldsToExport, destinationAppId 
                            });
          this.$eventBus.$emit('fieldsReadyToExport', { destinationBanner: newBanner, app_uid: this.destinationApps[i].uid, mode: 'BannerExport' });
        }
        this.$emit('close');
      }
      this.loader = false;
    },
    async validateForm() {
      try {
        if (!this.destinationApps.length) {
          throw 'Minimum 1 destination app required'
        }

        for (let i = 0; i < this.destinationApps.length; i++) {
          const iAppBanners = await this.$store.dispatch('getAppBanners', this.destinationApps[i].id);
          for (let j = 0; j < iAppBanners.length; j++) {
            if (iAppBanners[j].release_version === this.release_version) {
              throw 'Banner ' + this.release_version + ' already exsists in ' + this.destinationApps[i].title;
            }
          }
        }

        const release_version_expression = /^[0-9]{1,6}((\.[0-9]{1,6})?){0,3}$/g;
        const release_version_regex = new RegExp(release_version_expression);

        if (!this.release_version) {
          throw "Release version is required";
        } 
        else if (this.release_version == 0) {
          if (!this.min_client_version) {
            this.min_client_version = '0';
          } else if (!this.min_client_version.match(release_version_regex)) {
            throw "Incorrect min client verison";
          }
        } else if (!this.release_version.match(release_version_regex)) {
            throw "Invalid release version format";
        } else if (!this.release_date) {
            throw "Release date is required";
        }
        return true;
      } catch (e) {
        this.setMessage('error', e);
      }
    },
    // async onShowExported(exportResult) {
    //   // redirect to app
    //   this.$router.push('/apppage/' + exportResult.destinationAppId + '?exported=' + exportResult.newBanner.release_version);
    //   // remove active banner
    //   this.$store.commit('updateAutoSaveTimer', 3);
    //   this.$emit('close');
    // },
    // async $emit('close')() {
    //   await this.$store.dispatch('fetchApp', this.tempCurrentAppId);
    //   this.$emit('close');
    // },
    updateListSelects() {
      const checkboxNodes = document.querySelectorAll('.input-checkbox');
      for (let i = 0; i < checkboxNodes.length; i++) {
        if (~this.destinationApps.findIndex(app => app.index == i)) {
          checkboxNodes[i].checked = true;
        } else {
          checkboxNodes[i].checked = false;
        }
      }
    }
  },
  computed: {
    appsList() {
      return this.$store.getters['apps'];
    },
    autoSaveTimer() {
      return this.$store.getters['autoSaveTimer'];
    }
  },
  async mounted() {
    if (this.$store.getters.apps.length === 0) {
      await this.$store.dispatch('fetchApps');
    }
    if (Object.entries(this.banner).length) {
      this.release_version    = this.banner.release_version,
      this.release_date       = this.banner.release_date,
      this.min_client_version = this.banner.min_client_version,
      this.comment            = this.banner.comment,
      this.styles             = this.banner.styles
    }
  },
  watch: {
    'release_version': {
      handler(release_version) {
        if (release_version === '0' && release_version !== "") {
          this.showMinClientVersion = true;
        } else {
          this.showMinClientVersion = false;
        }
      },
      immediate: true
    },
    autoSaveTimer: {
      handler(value) {
        if (value !== 'Done') {
          this.loader = true; 
        } else {
          this.loader = false;
        }
      },
      immediate: true
    },
    showList(boolean) {
      if (boolean) {
        this.$nextTick(() => {
          this.updateListSelects();
        });
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@import '../../assets/mixins/mixins'

.banner-export
  @include modal
  .input-container,
  .buttons-container
    transition: .2s linear
    &.light
      opacity: 0
  .entry-list
    padding-top: 8px
  .entry-container
    justify-content: flex-start
    align-items: center
    &:hover
      cursor: pointer
  .input-list
    width: calc(100% - 155px)
    position: absolute
    top: 100%
    left: 155px
  .input-checkbox
    margin-right: 10px
  .input-date
    &::-webkit-inner-spin-button 
      -webkit-appearance: none
      display: none
    &::-webkit-calendar-picker-indicator
      background-image: url('../../assets/img/calendar.png')
      &:hover
        cursor: pointer
        background-image: url('../../assets/img/calendar_hover.png')
  &__form
    .input-container
      .input-title 
        width: 155px
      .loader-container_wait
        color: $grey-text

.message-enter, .message-leave-to
  opacity: 0

.message-enter-active, .message-leave-active
  transition: all .2s ease

.list-enter, .list-leave-to
  opacity: 0

.list-enter-active, .list-leave-active
  transition: all .2s ease

.buttons-show-enter, .buttons-show-leave-to
  opacity: 0

.buttons-show-enter-active, .buttons-show-leave-active
  transition: all .2s ease
  
@media screen and (max-width: 680px)
  .banner-export
    @include modal-max-680
</style>
