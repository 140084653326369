<template lang="html">
  <div class="preview">
    <!-- BANNER PREVIEW  -->
    <div
      class="preview__banner"
      :style="{ ...bannerStyles, 'padding-left': 0, 'padding-right': 0 }"
      ref="preview_banner"
    >
      <FieldGroupValue
        v-for="(fieldsGroup, index) in fieldsGroups"
        :key="index"
        :fieldsGroup="fieldsGroup"
        :bannerStyles="bannerStyles"
      />
    </div>
  </div>
</template>

<script>
import FieldGroupValue from '@/components/FieldGroupValue';

export default {
  components: { 
    FieldGroupValue
  },
  props: ['activeBanner'],
  data: () => ({
    fieldsGroups: []
  }),
  created() {
    this.$eventBus.$on('fieldsReadyToExport', async ({ destinationBanner, app_uid, mode }) => {
      const fields = await this.$store.dispatch('fetchSortedBannerFields', destinationBanner.id);
      const fieldsGroups = this.getFieldsGroups(fields);
      console.log('activeBanner', this.activeBanner);
      const html = await this.$store.dispatch('generateHtml', {
        fieldsGroups,
        bannerParams: {
          styles: this.bannerStyles,
          size: {
            width: this.activeBanner.styles.width,
            height: this.activeBanner.styles.height
          }
        }
      });
      // console.log('html', html);
      const banner = await this.$store.dispatch('exportHtml', { html, banner: destinationBanner, app_uid  });

      if (mode === 'BannerSave') {
        this.$store.commit('updateActiveBanner', banner);
      }
      this.autoSaveQuery = this.autoSaveQuery - 1;
      if (this.autoSaveTimer === 0 && this.autoSaveQuery === 0) {
        this.autoSaveTimer = 'Done';
      }
    });
  },
  beforeDestroy() {
    this.$eventBus.$off('fieldsReadyToExport');
  },
  methods: {
    getFieldsGroups(fields) {
      const fieldsGroups = [];
      for (let i = 0; i < fields.length; i++) {
        if (fields[i].type === 'combo') {
          const fieldsInGroup = fields.filter(field => field.related_group_id === fields[i].id);
          fieldsGroups.push([fields[i], ...fieldsInGroup]);
        } else if (!fields[i].related_group_id) {
          fieldsGroups.push([fields[i]]);
        }
      }
      return fieldsGroups;
    }
  },
  computed: {
    currentBannerFields() {
      return this.$store.getters['currentBannerFields'];
    },
    bannerStyles() {
      return {
        'width': this.activeBanner.styles['width'] + 'px',
        'height': this.activeBanner.styles['height'] === 'auto'
                  ? this.activeBanner.styles['height']
                  : this.activeBanner.styles['height'] + 'px',
        'padding-top': this.activeBanner.styles['padding-top'] + 'px',
        'padding-bottom': this.activeBanner.styles['padding-bottom'] + 'px',
        'padding-left': this.activeBanner.styles['padding-left'] + 'px',
        'padding-right': this.activeBanner.styles['padding-right'] + 'px',
        'background': this.activeBanner.styles['background']
      }
    },
    strapiHost() {
      return this.$store.getters.STRAPI_HOST;
    },
    autoSaveTimer: {
      get() {
        return this.$store.getters.autoSaveTimer;
      },
      set(time) {
        this.$store.commit('updateAutoSaveTimer', time);
      }
    },
    autoSaveQuery: {
      get() {
        return this.$store.getters.autoSaveQuery;
      },
      set(value) {
        this.$store.commit('updateAutoSaveQuery', value);
      }
    }
  },
  watch: {
    currentBannerFields: {
      handler(fields) {
        this.fieldsGroups = this.getFieldsGroups(fields);
      },
      immediate: true
    }
  }
}
</script>

<style lang="sass" scoped>
@import '../assets/mixins/mixins'

.preview
  position: relative
  &__banner
    margin: 0 auto
    box-shadow: 14.420px 14.420px 20px 0px rgba(93, 93, 93, 0.15)
    border: 1px solid $grey-title
    overflow: hidden
  .button_options
    display: block
    max-width: 100%
    text-align: right
    margin: 10px auto 0
    background: transparent
    color: $pink
    text-transform: uppercase
    border: none
    cursor: pointer
    font-family: $lato
    font-size: 14px
    position: relative
    margin-bottom: 20px
    &:hover 
      color: $red
      &::before
        background-image: url('../assets/img/edit_hover.png')
    &::before
      content: ''
      display: block
      width: 12px 
      height: 12px
      border: none
      background: none
      background-image: url('../assets/img/edit.png')
      background-size: contain
      background-repeat: no-repeat
      position: absolute
      top: 50%
      transform: translateY(-50%)
      right: 130px

</style>