<template lang="html">
  <div class="toolbar" v-if="activeField.type !== 'date'">
    <div 
      class="template template_text"
      v-if="activeField.type === 'headerh1'
            || activeField.type === 'headerh2'
            || activeField.type === 'text'
            || activeField.type === 'bulletpoints'
            || activeField.type === 'headerh1group'
            || activeField.type === 'headerh2group'
            || activeField.type === 'textgroup'
            || activeField.type === 'bulletpointsgroup'"
    >
      <div class="params-row">
        <!-- Font color -->
        <div class="param-container param-container_color" v-tooltip="'Font color (Hex)'">
          <div
            class="param-color__A-container"
            @click="$refs['input_color'].click()"
          >
            <span class="param-color__A">A</span>
            <div
              class="param-color__color-box"
              :style="{'background-color': fieldStyles['color']}">
            </div>
          </div>
          <input
            class="param-color__input-hex"
            type="text"
            v-model="fieldStyles['color']"
          >
          <input
            class="param-color__input-hidden"
            type="color"
            ref="input_color"
            v-model="fieldStyles['color']"
          >
        </div>
        <!-- Font size -->
        <div class="param-container param-container_size" v-tooltip="'Font size'">
          <input
            class="param-size__input"
            type="text"
            v-model="fieldStyles['font-size']"
            @keydown="arrowsHandler($event, 'font-size')"
          >
          <span class="param-size__unit">px</span>
        </div>
        <!-- Font weight -->
        <div
          class="param-container param-container_weight"
          @click="fieldStyles['font-weight'] === 'bold'
          ? fieldStyles['font-weight'] = 'normal'
          : fieldStyles['font-weight'] = 'bold'"
          :class="{'active': fieldStyles['font-weight'] === 'bold'}"
          v-tooltip="'Font weight'"
        >
          <span class="param-weight__B">B</span>
        </div>
        <!-- Font style -->
        <div
          class="param-container param-container_style"
          :class="{'active': fieldStyles['font-style'] === 'italic'}"
          @click="fieldStyles['font-style'] === 'italic'
          ? fieldStyles['font-style'] = 'normal'
          : fieldStyles['font-style'] = 'italic'"
          v-tooltip="'Font style'"
        >
          <span class="param-style__I">I</span>
        </div>
        <!-- Font decoration -->
        <div
          class="param-container param-container_decoration"
          :class="{'active': fieldStyles['text-decoration'] === 'underline'}"
          @click="fieldStyles['text-decoration'] === 'underline'
          ? fieldStyles['text-decoration'] = 'none'
          : fieldStyles['text-decoration'] = 'underline'"
          v-tooltip="'Font decoration'"
        >
          <span class="param-decoration__U">U</span>
        </div>
        <!-- Text shadow  -->
        <div
          class="param-container param-container_shadow"
          :class="{'active': fieldStyles['text-shadow'] !== 'none'}"
          @click="fieldStyles['text-shadow'] === 'none'
          ? fieldStyles['text-shadow'] = '1px 1px #262626'
          : fieldStyles['text-shadow'] = 'none'"
          v-tooltip="'Text shadow'"
        >
          <span class="param-shadow__S">S</span>
        </div>
        <!-- Margin top  -->
        <div
          class="param-container param-container_mt"
          v-tooltip="'Margin top'"
        >
          <input
            class="param-mt__input"
            type="text"
            v-model="fieldStyles['margin-top']"
            @keydown="arrowsHandler($event, 'margin-top')"
          >
          <span class="param-mt__unit">px</span>
        </div>
        <!-- Default styles -->
        <div
          class="param-container param-container_default"
        >
          <button
            class="param-default__button"
            type="button"
            @click="onSetDefaultStyles"
          >Default</button>
        </div>
        <!-- End of row -->
      </div>
      <div class="params-row">
        <!-- Font family -->
        <div 
          class="param-container param-container_family" 
          v-tooltip="'Font family'"
          @click="showDropdown.family = !showDropdown.family"
        >
          <div class="param-family__active">
            {{ fieldStyles['font-family'] }}
          </div>
          <ul 
            class="param-family__dropdown"
            v-show="showDropdown.family"
          >
            <li
              class="param-family__dropdown-item"
              v-for="(font, index) in fonts"
              :key="index"
              :style="{'font-family': font, 'display': fieldStyles['font-family'] === font ? 'none' : 'block'}"
              @click="fieldStyles['font-family'] = font"
            >
              {{ font }}
            </li>
          </ul>
        </div>
        <!-- Line height -->
        <div class="param-container param-container_line-height" v-tooltip="'Line height'">
          <input
            class="param-line-height__input"
            type="text"
            v-model="fieldStyles['line-height']"
          >
          <span class="param-line-height__unit">em</span>
        </div>
        <!-- Alignments -->
        <div
          class="param-container param-container_alignment"
          :class="{'active': fieldStyles['text-align'] === alignment}"
          v-for="(alignment, index) in alignments"
          :key="index"
          @click="fieldStyles['text-align'] = alignment"
          v-tooltip="alignment === 'justify' ? 'Justify' : 'Align ' + alignment"
        >
          <img
            class="param-alignment__image"
            :alt="alignment"
            :src="require(`../assets/img/align-${alignment}.png`)"
          >
        </div>
        <!-- Margin bottom -->
        <div
          class="param-container param-container_mb"
          v-tooltip="'Margin bottom'"
        >
          <input
            class="param-mb__input"
            type="text"
            v-model="fieldStyles['margin-bottom']"
            @keydown="arrowsHandler($event, 'margin-bottom')"
          >
          <span class="param-mb__unit">px</span>
        </div>
        <!-- Link -->
        <div
          class="param-container param-container_link"
          :class="{'active': actionUrl || showLinkInput}"
        >
          <button
            class="param-link__button"
            type="button"
            @click="showLinkInput = !showLinkInput"
          >Link</button>
        </div>
        <!-- End of row -->
      </div>
      <div class="params-row" v-if="showLinkInput">
        <div class="link-container">
          <input
            class="link-container__input"
            type="text"
            ref="input_url"
            placeholder="Paste link with 'https://' protocol and press 'Enter'"
            @keyup.enter="onAddActionUrl"
            :value="actionUrl"
          >
        </div>
        <!-- End of row -->
      </div>
      <!-- End of text template -->
    </div>
    
    <div 
      class="template template_image"
      v-if="activeField.type === 'image' || activeField.type === 'imagegroup'"
    >
      <div class="params-row">
        <!-- Image height -->
        <div
          class="param-container param-container_height"
          v-tooltip="'Image height'"
        >
          <input
            class="param-height__input"
            type="text"
            ref="image_height"
            v-model="fieldStyles['height']" 
            @keydown="arrowsHandler($event, 'height')"
            @focus="onImageHeightOrWidthFocus('height')"
            @blur="onImageHeightBlur"
          >
          <span
            class="param-height__unit"
            v-if="fieldStyles['height'] !== 'auto'"
          >px</span>
        </div>
        <!-- Image justify  -->
        <div
          class="param-container param-container_justification"
          v-for="(justify, index) in justifications"
          :key="index"
          :class="{'active': fieldStyles['justify-content'] === justify}"
          @click="onJustifyChange(justify)"
          v-tooltip="getJustifyTooltip(justify)"
        >
          <img
            class="param-alignment__image"
            :alt="alignments[index]"
            :src="require(`../assets/img/align-${alignments[index]}.png`)"
          >
        </div>
        <!-- Image default styles -->
        <div
          class="param-container param-container_default"
        >
          <button
            class="param-default__button"
            type="button"
            @click="onSetDefaultStyles"
          >Default</button>
        </div>
        <!-- End of row -->
      </div>
      <div class="params-row">
        <!-- Image fit -->
        <div
          class="param-container param-container_fit"
          v-tooltip="'Image fit'"
          @click="showDropdown.imageFit = !showDropdown.imageFit"
        >
          <div class="param-fit__active">
            {{ fieldStyles['object-fit'] }}
          </div>
          <div 
            class="param-fit__dropdown"
            v-show="showDropdown.imageFit === true"
          >
            <div
              class="param-fit__dropdown-item"
              v-for="(fit, index) in fits"
              :key="index"
              @click="onFitChange(fit)"
              :style="{'display': fieldStyles['object-fit'] === fit ? 'none' : 'block'}"
            >
              {{ fit }}
            </div>
          </div>
        </div>
        <!-- Image margin top  -->
        <div
          class="param-container param-container_mt"
          v-tooltip="'Margin top'"
        >
          <input
            class="param-mt__input"
            type="text"
            v-model="fieldStyles['margin-top']"
            @keydown="arrowsHandler($event, 'margin-top')"
          >
          <span class="param-mt__unit">px</span>
        </div>
        <!-- Image margin bottom -->
        <div
          class="param-container param-container_mb"
          v-tooltip="'Margin bottom'"
        >
          <input
            class="param-mb__input"
            type="text"
            v-model="fieldStyles['margin-bottom']"
            @keydown="arrowsHandler($event, 'margin-bottom')"
          >
          <span class="param-mb__unit">px</span>
        </div>
        <!-- Image link -->
        <div
          class="param-container param-container_link"
          :class="{'active': actionUrl || showLinkInput}"
        >
          <button
            class="param-link__button"
            type="button"
            @click="showLinkInput = !showLinkInput"
          >Link</button>
        </div>
        <!-- End of row -->
      </div>
      <transition name="input-link" mode="in-out">
        <div class="params-row" v-if="showLinkInput">
          <div class="link-container">
            <input
              class="link-container__input"
              type="text"
              ref="input_url"
              placeholder="Paste link with 'https://' protocol and press 'Enter'"
              @keyup.enter="onAddActionUrl"
              :value="actionUrl"
            >
          </div>
          <!-- End of row -->
        </div>
      </transition>
      <!-- End of image template -->
    </div>

    <div 
      class="template template_video"
      v-if="activeField.type === 'youtube' || activeField.type === 'youtubegroup'"
    >
      <div class="params-row">
        <!-- Video height -->
        <div
          class="param-container param-container_height"
          v-tooltip="'Video height'"
        >
          <input
            class="param-height__input"
            type="text"
            ref="image_height"
            v-model="fieldStyles['height']"
            @keydown="arrowsHandler($event, 'height')"
          >
          <span class="param-height__unit" v-if="fieldStyles['height'] !== 'auto'">px</span>
        </div>
        <!-- Video margin top  -->
        <div
          class="param-container param-container_mt"
          v-tooltip="'Margin top'"
        >
          <input
            class="param-mt__input"
            type="text"
            v-model="fieldStyles['margin-top']"
            @keydown="arrowsHandler($event, 'margin-top')"
          >
          <span class="param-mt__unit">px</span>
        </div>
        <!-- Video margin bottom -->
        <div
          class="param-container param-container_mb"
          v-tooltip="'Margin bottom'"
        >
          <input
            class="param-mb__input"
            type="text"
            v-model="fieldStyles['margin-bottom']"
            @keydown="arrowsHandler($event, 'margin-bottom')"
          >
          <span class="param-mb__unit">px</span>
        </div>
        <!-- Video default styles -->
        <div
          class="param-container param-container_default"
        >
          <button
            class="param-default__button"
            type="button"
            @click="onSetDefaultStyles"
          >Default</button>
        </div>
        <!-- End of row -->
      </div>
      <!-- End of video template -->
    </div>
    <!-- Combo template -->
    <div 
      class="template template_list"
      v-if="activeField.type === 'combo'"
    >
      <div class="params-row">
      <!-- Icon -->
        <div 
          class="param-container param-container_list-style" 
          v-tooltip="'Combo icon'"
          @click="showDropdown.comboIcon = !showDropdown.comboIcon"
        >
          <div class="param-list-style__active">
            {{ listIconActive.name }}
          </div>
          <ul 
            class="param-list-style__dropdown"
            v-show="showDropdown.comboIcon"
          >
            <li
              class="param-list-style__dropdown-item"
              v-for="(listIcon, index) in listIcons"
              :key="index"
              @click="listIconActive = listIcon"
            >
              {{ listIcon.name }}
            </li>
            <li
              class="param-list-style__dropdown-item"
              @click="listIconActive = { name: 'Custom' }"
            >
              Custom
            </li>
          </ul>
          <input
            class="input-image input-image_hidden"
            :style="{'display': 'none'}"
            type="file"
            accept="image/*"
            @change="onImageChange"
            ref="input_list-icon"
          >
        </div>
        <!-- Background color -->
        <div 
          class="param-container param-container_list-background" 
          v-tooltip="'Background color'"
          @click="showDropdown.comboBackground = !showDropdown.comboBackground"
        >
          <div class="param-list-background__active">
            Background
          </div>
          <ul 
            class="param-list-background__dropdown"
            v-show="showDropdown.comboBackground === true"
          >
            <li
              class="param-list-background__dropdown-item"
              v-for="(background, index) in listBackgrounds"
              :key="index"
              @click="listBackgroundActive = background"
            >
              {{ background.name }}
            </li>
          </ul>
        </div>
      <!-- End of row -->
      </div>
    <!-- End of list template -->
    </div>
    <!-- Feedback template  -->
    <div 
      class="template template_feedback"
      v-if="activeField.type === 'feedback'"
    >
      <div class="params-row">
        <!-- Feedback icons -->
        <div 
          class="param-container param-container_feedback-style"
          v-tooltip="'Feedback icons style'"
          @click="showDropdown.feedbackIcons = !showDropdown.feedbackIcons"
        >
          <div class="param-feedback-style__active">
            <img 
              class="feedback-icon"
              :src="ENV_STRAPI_HOST + feedbackActiveStyle.data.goodActive"
              v-if="feedbackActiveStyle.data.goodActive"
            >
            <img 
              class="feedback-icon"
              :src="ENV_STRAPI_HOST + feedbackActiveStyle.data.neutralActive"
              v-if="feedbackActiveStyle.data.neutralActive"
            >
            <img 
              class="feedback-icon"
              :src="ENV_STRAPI_HOST + feedbackActiveStyle.data.badActive"
              v-if="feedbackActiveStyle.data.badActive"
            >
          </div>
          <ul 
            class="param-feedback-style__dropdown"
            v-show="showDropdown.feedbackIcons === true"
          >
            <li
              class="param-feedback-style__dropdown-item"
              v-for="(feedbackStyle, index) in feedbackStyles"
              :key="index"
              :style="{'display': feedbackStyle.name === feedbackActiveStyle.name ? 'none' : 'flex'}"
              @click="feedbackActiveStyle = feedbackStyle"
            >
              <img 
                class="feedback-icon"
                :src="ENV_STRAPI_HOST + feedbackStyle.data.goodActive"
                v-if="feedbackStyle.data.goodActive"
              >
              <img 
                class="feedback-icon"
                :src="ENV_STRAPI_HOST + feedbackStyle.data.neutralActive"
                v-if="feedbackStyle.data.neutralActive"
              >
              <img 
                class="feedback-icon"
                :src="ENV_STRAPI_HOST + feedbackStyle.data.badActive"
                v-if="feedbackStyle.data.badActive"
              >
            </li>
            <li 
              class="param-feedback-style__dropdown-item"
              @click="$emit('showFeedbackModal', { activeField })"
            >Custom</li>
          </ul>
        </div>
        <!-- Feedback alignments -->
        <div
          class="param-container param-container_justification"
          v-for="(justify, index) in justifications"
          :key="index"
          :class="{'active': fieldStyles['justify-content'] === justify}"
          @click="onJustifyChange(justify)"
          v-tooltip="getJustifyTooltip(justify)"
        >
          <img
            class="param-alignment__image"
            :alt="alignments[index]"
            :src="require(`../assets/img/align-${alignments[index]}.png`)"
          >
        </div>
        <!-- Feedback margin top -->
        <div
          class="param-container param-container_mt"
          v-tooltip="'Margin top'"
        >
          <input
            class="param-mt__input"
            type="text"
            v-model="fieldStyles['margin-top']"
            @keydown="arrowsHandler($event, 'margin-top')"
          >
          <span class="param-mt__unit">px</span>
        </div>
        <!-- Feedback margin bottom -->
        <div
          class="param-container param-container_mb"
          v-tooltip="'Margin bottom'"
        >
          <input
            class="param-mb__input"
            type="text"
            v-model="fieldStyles['margin-bottom']"
            @keydown="arrowsHandler($event, 'margin-bottom')"
          >
          <span class="param-mb__unit">px</span>
        </div>
        <!-- End of param row -->
      </div>
      <!-- End of feedback template -->
    </div>
  <!-- End of toolbar -->
  </div>
</template>

<script>
export default {
  props: ['activeField', 'listIcons'],
  data: () => ({
    weights:        ['normal', 'bold'],
    fonts:          ['Open Sans', 'Poppins', 'Muli'],
    styles:         ['normal', 'italic'],
    decorations:    ['none', 'underline'],
    shadows:        ['none', '1px 1px #000'],
    alignments:     ['left', 'center', 'right', 'justify'],
    margins:        ['0', '5', '10', '15', '20', '30', '40', '50'],
    justifications: ['flex-start', 'center', 'flex-end'],
    fits:           ['none', 'cover', 'contain', 'fill'],
    listIconActive: {
      name: 'Function'
    },
    listColors: [
      {
        name: 'Yellow',
        color: '#de9905'
      },
      {
        name: 'Green',
        color: '#628c3c'
      },
      {
        name: 'Red',
        color: '#ab4922'
      },
      {
        name: 'Blue',
        color: '#2B579A'
      }
    ],
    listColorActive: {
      name: 'Select'
    },
    listBackgrounds: [
      {
        name: 'Grey',
        color: '#f5f6f7'
      },
      {
        name: 'Transparent',
        color: 'transparent'
      } 
    ],
    listBackgroundActive: {
      name: 'Select'
    },

    templateId: '',

    showLinkInput: false,

    allowAutoSave: true,

    showDropdown: {
      family: false,
      imageFit: false,
      comboIcon: false,
      comboBackground: false,
      feedbackIcons: false
    },

    feedbackStyles: [], 
    feedbackActiveStyle: {
      data: {}
    }
  }),
  async mounted() {
    if (this.activeField.type === 'combo') {
      if (!this.activeField.value) {
        this.listIconActive.name = 'Custom';
      } else if (~this.activeField.value.indexOf('update')) {
        this.listIconActive = this.listIcons.find(i => i.name === 'Update');
      } else if (~this.activeField.value.indexOf('bug_fix')) {
        this.listIconActive = this.listIcons.find(i => i.name === 'Bug fix');
      } else if (~this.activeField.value.indexOf('improve')) {
        this.listIconActive = this.listIcons.find(i => i.name === 'Improve');
      } 
    } else if (this.activeField.type === 'feedback') {
      try {
        this.feedbackStyles = this.$store.getters.teamFeedbackIcons;
        if (this.activeField.value && this.activeField.value.startsWith('assetId')) {
          const assetId = this.activeField.value.split('=')[1];
          this.feedbackActiveStyle = this.feedbackStyles.find(fS => fS.id == assetId);
        } else {
          this.feedbackActiveStyle = this.feedbackStyles[0];
        }
      } catch (e) {
        console.log('Error on fetch feedback icons', e);
      }
    }
  },
  methods: {
    async onImageChange(e) {
      const targetImage = e.target.files[0];
      if (targetImage.size > 1048576) {
        alert('Maximum image size - 1MB');
        this.$refs['input_list-icon'].value = "";
        return;
      }
      const imageToDelete = this.activeField.image;
      
      this.$emit('setActiveListIcon', { iconName: 'custom', iconImage: targetImage, iconLink: '' });

      if (imageToDelete && ('id' in imageToDelete)) {
        await this.$store.dispatch('deleteImage', imageToDelete.id);
      }

      this.$store.commit('updateAutoSaveTimer', 3);
    },
    onAddActionUrl() {
      let link = this.$refs['input_url'].value;
      let regExpression = /^(https:\/\/)(www\.)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;


      if (link.startsWith('mailto:')) {
        regExpression = /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/g;
      }

      const linkRegex = new RegExp(regExpression);

      if (link.startsWith('mailto:') ? link.slice(7).match(linkRegex) : link.match(linkRegex) || link === "") {
        this.actionUrl = link;
        this.showLinkInput = false;

        this.$store.commit('updateAutoSaveTimer', 3);
      } else {
        alert('Incorrect link format')
      }
    },
    onSetDefaultStyles() {
      this.fieldStyles = {...this.defaultStyles};
    },
    // onSaveDefaultTemplate() {
    //   const template = {
    //     type: this.activeField.type,
    //     styles: {...this.fieldStyles}
    //   };
    //   this.$store.dispatch('saveTemplate', template);
    // },

    onJustifyChange(justify) {
      if ((this.activeField.type === 'image' || this.activeField.type === 'imagegroup')
          && this.activeField.styles['width'] === '100%') {
          this.activeField.styles['width'] = 'auto';
          this.fieldStyles['object-fit'] = 'contain';
      }
      this.activeField.styles['justify-content'] = justify;
    },
    getJustifyTooltip(justify) {
      if (justify === 'flex-start') {
        return 'Align left';
      } else if (justify === 'center') {
        return 'Align center';
      } else if (justify === 'flex-end') {
        return 'Align right';
      }
    },
    onFitChange(fit) {
      if ((this.activeField.type === 'image' || this.activeField.type === 'imagegroup')
          && this.activeField.styles['width'] === 'auto') {
          this.activeField.styles['width'] = '100%';
      }
      this.fieldStyles['object-fit'] = fit;
    },
    arrowsHandler(e, type) {
      if (e.keyCode === 38) {
        // ARROW UP
        e.preventDefault();
        if (this.fieldStyles[type] === 'auto' || this.fieldStyles[type] === '100%') {
          this.fieldStyles[type] = '100'
        } else {
          ++this.fieldStyles[type];
        }
      } else if (e.keyCode === 40) {
        // ARROW DOWN
        e.preventDefault();
        if (this.fieldStyles[type] === 'auto' || this.fieldStyles[type] === '100%') {
          this.fieldStyles[type] = '100'
        } else if (this.fieldStyles[type] > 0) {
          --this.fieldStyles[type];
        }
      }
    },
    onImageHeightOrWidthFocus(type) {
      this.$refs[`image_${type}`].select();
      this.fieldStyles['border-bottom'] = "1px dashed #cc7586";
      this.fieldStyles['border-top'] = "1px dashed #cc7586";
    },
    onImageHeightBlur() {
      this.fieldStyles['border-bottom'] = "none";
      this.fieldStyles['border-top'] = "none";
    }
  },
  computed: {
    actionUrl: {
      get() {
        return this.activeField.action_url;
      },
      set(link) {
        this.activeField.action_url = link;
      }
    },
    fieldStyles: {
      get() {
        return this.activeField.styles;
      },
      set(styles) {
        this.activeField.styles = styles;
      }
    },
    defaultStyles() {
      const defaultTemplate = this.templates.find(t => t.type === this.activeField.type);
      return {...defaultTemplate.styles};
    },
    templates() {
      return this.$store.getters['templates'];
    }
  },
  watch: {
    activeField() {
      this.allowAutoSave = false;
    },
    fieldStyles: {
      handler() {
        if (this.allowAutoSave) {
          this.allowAutoSave = false;
          this.$store.commit('updateAutoSaveTimer', 3);
        }
      },
      deep: true
    },
    allowAutoSave(value) {
      if (value === false) {
        setTimeout(() => {
          this.allowAutoSave = true;
        }, 200);
      }
    },
    listIconActive: {
      async handler(icon) {
        if (this.activeField.type === 'combo') {
          if (icon.name === 'Custom') {
            this.$refs['input_list-icon'].click();
            return;
          }

          const imageToDelete = this.activeField.image;

          this.$emit('setActiveListIcon', { iconName: icon.name, iconLink: icon.link, iconImage: '' });
          const iconColor = this.listColors.find(color => color.name === icon.color);
          this.$emit('setActiveListColor', iconColor.color);  

          if (imageToDelete && ('id' in imageToDelete)) {
            await this.$store.dispatch('deleteImage', imageToDelete.id);
          }
        }
      },
      deep: true
    },
    listColorActive: {
      handler(color) {
        this.$emit('setActiveListColor', color.color);
      },
      deep: true
    },
    listBackgroundActive: {
      handler(background) {
        this.fieldStyles['background-color'] = background.color;
      },
      deep: true
    },
    feedbackActiveStyle: {
      handler(feedbackStyle) {
        this.$emit('setActiveFeedbackStyle', feedbackStyle)
      },
      deep: true
    }
  }
}
</script>

<style lang="sass" scoped>
@import '../assets/variables/variables'

@mixin input
  background: transparent
  border: none
  border-bottom: 1px solid $pink
  transition: border-color .1s linear
  padding: 2px 0
  color: $grey-text
  font-size: 14px
  font-weight: 400
  text-align: right
  font-size: 14px
  &:hover,
  &:focus
    border-color: $red
  &::placeholder
    font-size: 13px

@mixin unit
  font-size: 13px
  margin-left: 5px

@mixin style
  color: $pink
  font-size: 14px

@mixin button
  height: 100%
  border: none
  background-color: transparent
  color: $pink
  font-size: 13px
  padding: 0 10px
  width: 100%
  &:hover
    cursor: pointer

@mixin before
  content: ''
  display: block
  width: 8px
  height: 8px
  transform: translateY(-50%)
  background-size: contain
  background-repeat: no-repeat
  position: absolute
  top: 50%

@mixin list
  &__active
    font-size: 14px
  &__dropdown
    width: calc(100% + 2px)
    position: absolute
    top: 100%
    left: -1px
    list-style: none
    border: 1px solid $grey-text
    border-top: 0
  &__dropdown-item
    font-size: 14px
    background-color: #fff
    padding: 5px 10px
    border-top: 1px solid $grey-title
    text-align: left
    &:hover
      background-color: $pink-light
      cursor: pointer

.toolbar
  max-height: 100px   
  z-index: 9
  position: relative

  .params-row
    display: flex
    border-top: 1px solid $grey-text

  .param-container
    background-color: #fff
    display: flex
    justify-content: center
    align-items: center
    padding: 5px 10px
    transition: .1s linear
    width: calc((100% - 200px - 130px) / 4)
    &:hover,
    &.active
      background-color: $pink-light
    &_weight,
    &_style,
    &_decoration,
    &_shadow,
    &_alignment,
    &_link,
    &_justification,
    &_group
      cursor: pointer
    &_default,
    &_link,
    &_group
      padding: 0
    &_mt
      padding-left: 15px
      position: relative
      &::before
        @include before
        background-image: url('../assets/img/s-arrow-up.png')
        left: 7px
    &_mb
      padding-left: 15px
      position: relative
      &::before
        @include before
        background-image: url('../assets/img/s-arrow-down.png')
        left: 7px
    &_family
      position: relative
      justify-content: flex-start
      &::before
        @include before
        background-image: url('../assets/img/arrow.png')
        top: 18.5px
        right: 7px
      &:hover
        cursor: pointer
        &::before
          background-image: url('../assets/img/arrow-hover.png')
    &_height
      position: relative
      &::before
        @include before
        background-image: url('../assets/img/s-arrow-vertical.png')
        left: 10px
    &_fit
      position: relative
      &::before
        @include before
        background-image: url('../assets/img/arrow.png')
        top: 18.5px
        right: 7px
      &:hover
        cursor: pointer
        &::before
          background-image: url('../assets/img/arrow-hover.png')
    &_list-style
      position: relative
      justify-content: flex-start
      &::before
        @include before
        background-image: url('../assets/img/arrow.png')
        top: 18.5px
        right: 7px
      &:hover
        cursor: pointer
        &::before
          background-image: url('../assets/img/arrow-hover.png')
    &_list-color
      position: relative
      justify-content: flex-start
      &::before
        @include before
        background-image: url('../assets/img/arrow.png')
        top: 18.5px
        right: 7px
      &:hover
        &::before
          background-image: url('../assets/img/arrow-hover.png')   
    &_list-background
      position: relative
      justify-content: flex-start
      &:hover
        cursor: pointer
      &::before
        @include before
        background-image: url('../assets/img/arrow.png')
        top: 18.5px
        right: 7px
      &:hover
        &::before
          background-image: url('../assets/img/arrow-hover.png')
    &_feedback-style
      position: relative
      justify-content: flex-start
      &:hover
        cursor: pointer
      &::before
        @include before
        background-image: url('../assets/img/arrow.png')
        top: 18.5px
        right: 7px
      &:hover
        &::before
          background-image: url('../assets/img/arrow-hover.png')
    &:not(:last-child)
      border-right: 1px solid $grey-text

  .param-color
    &__A-container
      margin-right: 10px
      cursor: pointer
    &__A
      color: $pink
    &__color-box
      height: 2px
    &__input-hex
      @include input
      width: 60px
    &__input-hidden
      display: none

  .param-size
    &__input
      @include input
      width: 20px
    &__unit
      @include unit
  
  .param-weight
    &__B
      @include style

  .param-style
    &__I
      @include style

  .param-decoration
    &__U
      @include style

  .param-shadow
    &__S
      @include style
  
  .param-mt
    &__input
      @include input
      width: 20px
    &__unit
      @include unit

  .param-default
    &__button
      @include button

  .param-family
    @include list

  .param-line-height
    &__input
      @include input
      width: 20px
    &__unit
      @include unit

  .param-mb
    &__input
      @include input
      width: 20px
    &__unit
      @include unit
  
  .param-link
    &__button
      @include button

  .link-container
    width: 100%
    padding: 5px 10px
    background-color: #fff
    max-height: 33px
    &__input
      @include input
      width: 100%
      text-align: left
  
  .param-height
    &__input
      @include input
      width: 30px
    &__unit
      @include unit
  
  .param-width
    &__input
      @include input
      width: 35px
    &__100
      @include button
      width: calc(100% + 2px)
      position: absolute
      top: 100%
      left: -1px
      display: none
      background-color: #fff
      border: 1px solid $grey-text
      border-top-color: $grey-title
      &:hover
        background-color: $pink-light
    &__unit
      @include unit
  
  .param-fit
    @include list
    &__active,
    &__dropdown-item
      text-transform: capitalize

  .param-list-style
    @include list
  
  .param-list-color
    @include list
  
  .param-list-background
    @include list

  .param-feedback-style
    @include list
    &__active,
    &__dropdown-item
      display: flex
      .feedback-icon
        width: 16px
        height: 16px
        &:not(:last-child)
          margin-right: 3px
      &:last-child
        color: $pink
      
  .template_text
    .param-container
      &:first-child,
      &:last-child
        width: 100px
      &:nth-child(2),
      &:nth-last-child(2)
        width: 65px


  .template_image
    .params-row
      &:nth-child(1)
        .param-container
          width: calc((100% - 240px) / 3)
      &:nth-child(2)
        .param-container
          width: calc((100% - 240px) / 2)
    .param-container
      &:first-child,
      &:last-child
        width: 120px !important
  
  .template_video
    .param-container
      width: calc(100% / 4)

  .template_list
    .param-container
      width: calc(100% / 2)
  
  .template_feedback
    .params-row
      .param-container
        width: calc((100% - 100px) / 5)
        &:first-child
          width: 100px

@media screen and (min-width: 1760px) 
  .toolbar
    .template_text
       .params-row
         .param-container
            &:first-child,
            &:last-child
              width: 100px
            &:nth-child(2),
            &:nth-last-child(2)
              width: 90px
            width: calc((100% - 200px - 180px) / 4)    
</style>