var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onResetActiveFieldIndex),expression:"onResetActiveFieldIndex"}],staticClass:"editor",on:{"mouseup":_vm.onStopDragging}},[_c('div',{staticClass:"editor__field-types"},[_vm._l((_vm.fieldTypes),function(fieldType,index){return _c('div',{key:index,staticClass:"field-type",class:("field-type_" + (fieldType.toLowerCase().replace(' ', ''))),style:({'display': fieldType === 'Feedback' && !_vm.isFeedbackAvaliable() ? 'none' : 'block'}),on:{"click":function($event){return _vm.onAddNewField(fieldType)}}},[_c('span',{staticClass:"field-type__name"},[_vm._v(_vm._s(_vm.activeGroupId ? fieldType.slice(0, -6) : fieldType))])])}),(_vm.newFieldLoader)?_c('div',{staticClass:"new-field-loader"},[_c('img',{staticClass:"loader",attrs:{"src":require('../assets/img/autosave_saving.gif')}})]):_vm._e()],2),_c('div',{ref:"fields-container",staticClass:"editor__fields",on:{"mousemove":function($event){return _vm.onDoDragging($event)}}},_vm._l((_vm.currentBannerFields),function(field,index){return _c('div',{key:index,ref:'field-' + index,refInFor:true,staticClass:"field",class:{
        'active': index === _vm.activeField.field_index 
                  || _vm.activeGroupId === field.id 
                  || ((_vm.activeGroupId === field.id) && (field.related_group_id === _vm.activeGroupId)),
        'dragging': ~_vm.draggingFields.findIndex(function (dF) { return dF.id === field.id; }),
        'hidden': field.related_group_id && field.related_group_id !== _vm.activeListId,
        'showed': field.related_group_id && field.related_group_id === _vm.activeListId
      },on:{"click":function($event){return _vm.onSetActiveField(field)}}},[_c('div',{staticClass:"field-container"},[(!(field.related_group_id && field.related_group_id === _vm.activeListId))?_c('button',{staticClass:"field-button field-button_move",on:{"mousedown":function($event){$event.stopPropagation();return _vm.onStartDragging($event, field)}}}):_c('span',{staticClass:"group-index"}),_c('div',{staticClass:"field-image",class:field.type}),_c('FieldInput',{attrs:{"field":field}}),_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.activeGroupId),expression:"!activeGroupId"}],staticClass:"field-button field-button_delete",on:{"click":function($event){$event.stopPropagation();return _vm.onDeleteField(field)}}})],1),_c('div',{staticClass:"toolbar-container"},[_c('transition',{attrs:{"name":"toolbar","mode":"in-out"}},[(index === _vm.activeField.field_index)?_c('Toolbar',{attrs:{"activeField":_vm.activeField,"listIcons":_vm.comboIconsList},on:{"setActiveListIcon":_vm.onSetActiveListIcon,"setActiveListColor":_vm.onSetActiveListColor,"setActiveFeedbackStyle":_vm.onSetActiveFeedbackStyle,"showFeedbackModal":function($event){return _vm.$emit('showFeedbackModal', { activeField: _vm.activeField })}}}):_vm._e()],1)],1)])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }