module.exports = [
    "Date", 
    "Header H1", 
    "Header H2", 
    "Text", 
    "Image", 
    "Youtube", 
    "Bulletpoints", 
    "Combo", 
];

// "Feedback"