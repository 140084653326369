<template>
  <div 
    class="editor"
    @mouseup="onStopDragging"
    v-click-outside="onResetActiveFieldIndex"
  >
    <div class="editor__field-types">
      <!-- <span class="add-to-group" v-if="activeGroupId" v-tooltip="'New field will be added to an active group'">Add to group</span> -->
      <div 
        class="field-type"
        v-for="(fieldType, index) in fieldTypes"
        :key="index"
        :class="`field-type_${fieldType.toLowerCase().replace(' ', '')}`"
        @click="onAddNewField(fieldType)"
        :style="{'display': fieldType === 'Feedback' && !isFeedbackAvaliable() ? 'none' : 'block'}"
      >
        <span class="field-type__name">{{ activeGroupId ? fieldType.slice(0, -6) : fieldType }}</span>
      </div>
      <div class="new-field-loader" v-if="newFieldLoader">
        <img class="loader" :src="require('../assets/img/autosave_saving.gif')">
      </div>
    </div>

    <div 
      class="editor__fields"
      ref="fields-container"
      @mousemove="onDoDragging($event)"
    >
      <div 
        class="field"
        v-for="(field, index) in currentBannerFields"
        :key="index"
        :class="{
          'active': index === activeField.field_index 
                    || activeGroupId === field.id 
                    || ((activeGroupId === field.id) && (field.related_group_id === activeGroupId)),
          'dragging': ~draggingFields.findIndex(dF => dF.id === field.id),
          'hidden': field.related_group_id && field.related_group_id !== activeListId,
          'showed': field.related_group_id && field.related_group_id === activeListId
        }"
        @click="onSetActiveField(field)"
        :ref="'field-' + index"
      >
        <div class="field-container">
          <button
            class="field-button field-button_move"
            v-if="!(field.related_group_id && field.related_group_id === activeListId)"
            @mousedown.stop="onStartDragging($event, field)"
          ></button>
          <span 
            class="group-index"
            v-else
          ></span>
          <div
            class="field-image"
            :class="field.type"
          ></div>
          <FieldInput
            :field="field"
          />
          <button
            class="field-button field-button_delete"
            @click.stop="onDeleteField(field)"
            v-show="!activeGroupId"
          ></button>
        </div>
        <div class="toolbar-container">
          <transition name="toolbar" mode="in-out">
            <Toolbar
              v-if="index === activeField.field_index"
              :activeField="activeField"
              :listIcons="comboIconsList"
              @setActiveListIcon="onSetActiveListIcon"
              @setActiveListColor="onSetActiveListColor"
              @setActiveFeedbackStyle="onSetActiveFeedbackStyle"
              @showFeedbackModal="$emit('showFeedbackModal', { activeField })"
            />
          </transition>
        </div>
      </div>
    </div>
  <!-- End of editor -->
  </div>
</template>

<script>
import FieldInput from '@/components/FieldInput';
import Toolbar from '@/components/Toolbar';
import singleFieldTypes from '@/data/fieldTypes/singleFieldTypes.data'
// import groupFieldTypes from '@/data/fieldTypes/groupFieldTypes.data'

import ClickOutside from 'vue-click-outside'

export default {
  props: ['activeBanner', 'stopDragging'],
  components: {
    FieldInput,
    Toolbar
  },
  directives: {
    ClickOutside
  },
  data: () => ({
    fieldTypes: [],
    templates: [],
    // activeGroupId is for active group fields type
    activeGroupId: null,
    // activeListId is for show related fields
    activeListId: null,
    fieldsColors: {},
    fieldsToRemove: [],
    fieldsGroups: [],

    draggingTimeout: null,
    draggingFields: [],
    draggingContainer: '',
    editorContainerOffsetTop: '',
    fieldsOffsets: [],
    neighbourBottom: null,
    neighbourTop: null,
    startPoint: '',

    comboIconsList: [],

    newFieldLoader: false
  }),
  async created() {
    this.$eventBus.$on('save', async () => {
      try {
        await this.onSaveFields();
        this.$eventBus.$emit('fieldsReadyToExport', { destinationBanner: this.activeBanner, app_uid: this.currentApp.uid, mode: 'BannerSave' });
      } catch (e) {
        this.$store.dispatch('setError', e);
      }
    });

    this.fieldTypes = singleFieldTypes;
    this.templates = this.$store.getters['templates'];
    this.comboIconsList = this.$store.getters['comboIcons'];
  },
  beforeDestroy() {
    this.$eventBus.$off('save');
  },
  methods: {
    updateTextareas() {
      const textareas = document.querySelectorAll('textarea');
      for (let i = 0; i < textareas.length; i++) {
        this.$autosize(textareas[i]) 
        this.$autosize.update(textareas[i])
      }
    },
    onSetActiveField(field) {
      if (field.type === 'combo') {
        this.activeListId = field.id;
        this.$nextTick(() => {
          this.updateTextareas();
        })
      } else if (!field.related_group_id) {
        this.activeListId = null;
      }
      this.activeFieldIndex = field.field_index;
    },
    onResetActiveFieldIndex() {
      this.activeFieldIndex = -1;
      this.activeListId = null;
    },
    onSetActiveListIcon({ iconName, iconImage, iconLink }) {
      if (iconName === 'custom') {
        this.activeField.image = iconImage;
        this.activeField.value = '';
      } else {
        this.activeField.image = '';
        this.activeField.value = iconLink;
      }
    },
    onSetActiveListColor(color) {
      const activeListId = this.activeField.id;
      const activeGroupIndexes = this.fieldsGroups.find(fG => fG.id === activeListId).fields_indexes;
      const currentBannerFields = this.currentBannerFields.map(field => {
        if (activeGroupIndexes.includes(field.field_index) && (field.type === 'headerh1group' || field.type === 'headerh2group')) {
          field.styles['color'] = color;
        }
        return field;
      })
      this.$store.commit('updateCurrentBannerFields', currentBannerFields);
    },
    async onAddNewField(fieldType, options = { active: true, focus: true, activeGroupId: null }) {
      try {
        this.newFieldLoader = true;
        fieldType = fieldType.toLowerCase();
        fieldType = fieldType.split(' ').join('')
        const fieldStyles = this.templates.find(t => t.type === fieldType);
        let value;
        if (fieldType === 'date') {
          value = this.activeBanner.release_date;
        } else if (fieldType === 'combo') {
          const functionIcon = this.comboIconsList.find(i => i.name === 'Function');
          value = functionIcon.link;
          if (this.fieldsGroups.length % 2 === 0) {
            fieldStyles.styles['background-color'] = '#f5f6f7';
          } else {
            fieldStyles.styles['background-color'] = 'transparent'
          }
        } else {
          value = '';
        }

        if (options.activeGroupId) {
          this.activeGroupId = options.activeGroupId;
        }
        this.updateFieldsIndexes();
        this.updateGroups();
        let field_index;
        if (this.activeGroupId) {
          const activeFieldsGroup = this.fieldsGroups.find(group => group.id === this.activeGroupId)
          const lastFieldIndexInActiveGroup = activeFieldsGroup.fields_indexes[activeFieldsGroup.fields_indexes.length - 1];
          field_index = lastFieldIndexInActiveGroup + 1;
          if (this.currentBannerFields.length >= field_index + 1) {
            for (let i = field_index; i < this.currentBannerFields.length; i++) {
              this.currentBannerFields[i].field_index = this.currentBannerFields[i].field_index + 1; 
            }
          }
        }
        else field_index = this.currentBannerFields.length; 
        
        // Make field object
        let field = {
          uid: Date.now(),
          type: fieldType,
          value,
          image: '',
          action_url: '',
          field_index,
          styles: {...fieldStyles.styles},
          banner: this.activeBanner.id,
          related_group_id: this.activeGroupId
        };
        // For autofocus on field add
        if (options.focus) {
          field.autofocus = true;
        }

        // Add field to state
        this.$store.commit('addCurrentBannerField', { field, field_index })
        if (options.active) {
          this.$store.commit('updateActiveFieldIndex', field_index);
        }
  
        if (fieldType === 'combo') {
          const loadedField = await this.$store.dispatch('loadField', field);
          this.activeListId = loadedField.id;
          this.$store.commit('updateCurrenBannerFieldByIndex', loadedField)
          this.onAddNewField('Header H1 Group', { active: false, focus: false, activeGroupId: loadedField.id });
          this.onAddNewField('Header H2 Group', { active: false, focus: false, activeGroupId: loadedField.id });
          this.onAddNewField('Text group', { active: false, focus: false, activeGroupId: loadedField.id });
        } else if (!options.activeGroupId) {
          this.activeListId = null;
        }
        
        if (fieldType === 'date') {
          this.$store.commit('updateAutoSaveTimer', 3);
        }

        if (this.activeGroupId) {
          this.activeGroupId = null;
        }
        this.updateFieldsIndexes();
        this.updateGroups();
        this.newFieldLoader = false;
      } catch(e) {
        console.log(e);
      }
    },

    async onSaveFields() {
      this.loader = true;
      this.autoSaveQuery = this.autoSaveQuery + 1;
      // SAVE BANNER STYLES
      const newBannerData = {...this.activeBanner};
      delete newBannerData.fields;
      const bannerId = newBannerData.id;
      await this.$store.dispatch('editBanner', { bannerId, newBannerData });
      // SAVE FIELDS
      for (let i = 0; i < this.currentBannerFields.length; i++) {
        const field = this.currentBannerFields[i];
        if (field.id) {
          await this.$store.dispatch('updateField', field);
        } else {
          const loadedField = await this.$store.dispatch('loadField', field);
          this.currentBannerFields[i].id = loadedField.id;
          this.currentBannerFields[i].image = loadedField.image;
        }
      }
      // DELETE FIELDS TO REMOVE
      for (let i = 0; i < this.fieldsToRemove.length; i++) {
        await this.$store.dispatch('deleteField', this.fieldsToRemove[i]);
      }
      this.fieldsToRemove = [];

      this.loader = false;
      console.log('on done save fields');
    },
    async onDeleteField(field) {
      if (field.id) {
        const fieldGroup = this.fieldsGroups.find(fG => fG.id === field.id);  
        if (fieldGroup) {
          const fieldsToRemove = []
          for (let i = 0; i < fieldGroup.fields_indexes.length; i++) {
            const fieldIndex = fieldGroup.fields_indexes[i];
            const fieldToRemove = this.currentBannerFields[fieldIndex];
            fieldsToRemove.push(fieldToRemove)
          }
          for (let i = 0; i < fieldsToRemove.length; i++) {
            const fieldToRemove = fieldsToRemove[i];
            const fieldIndex = fieldToRemove.field_index;
            this.$store.commit('removeCurrentBannerFieldByIndex', fieldIndex);
            this.fieldsToRemove.push(fieldToRemove);
          }
          this.updateFieldsIndexes();
          this.updateGroups();
          this.$nextTick(() => {
            this.updateTextareas();
          })
          this.$store.commit('updateActiveFieldIndex', -1);
          this.$store.commit('updateAutoSaveTimer', 3);
          return;
        }
      }

      this.$store.commit('removeCurrentBannerFieldByIndex', field.field_index);
      this.$store.commit('updateActiveFieldIndex', -1);
      // add field to fields to remove list
      this.fieldsToRemove.push(field);
      this.updateFieldsIndexes();
      this.updateGroups();
      this.$nextTick(() => {
        this.updateTextareas();
      })
      this.$store.commit('updateAutoSaveTimer', 3);
    },
    updateGroupsBackgrounds() {
      for (let i = 0; i < this.fieldsGroups.length; i++) {
        const groupFieldIndex = this.fieldsGroups[i].fields_indexes[0];
        if (i % 2 === 0) {
          this.currentBannerFields[groupFieldIndex].styles['background-color'] = '#f5f6f7';
        } else {
          this.currentBannerFields[groupFieldIndex].styles['background-color'] = 'transparent';
        }
      }
    }, 
    updateFieldsIndexes() {
      for (let i = 0; i < this.currentBannerFields.length; i++) {
        this.currentBannerFields[i].field_index = i;
      }
    }, 
    onStartDragging(e, field) {
      // Close toolbar
      let animationTimeout = 0;
      if (this.activeListId) {
        this.activeListId = null;
        animationTimeout = 300;
      }
      if (this.activeFieldIndex !== -1) {
        this.$store.commit('updateActiveFieldIndex', -1);
        animationTimeout += 200;
      }

      this.draggingTimeout = setTimeout(() => {
        this.$nextTick(() => {
          // Define fields to move
          this.draggingFields.push(field)
          if (field.id) {
            const fieldGroup = this.fieldsGroups.find(fG => fG.id === field.id);
            if (fieldGroup) {
              for (let i = 1; i < fieldGroup.fields_indexes.length; i++) {
                const fieldToMoveIndex = fieldGroup.fields_indexes[i];
                const fieldToMove = this.currentBannerFields[fieldToMoveIndex];
                this.draggingFields.push(fieldToMove);
              }
            }
          }
          // Make fields clones and append to fields container
          const fieldsContainer = this.$refs['fields-container']; 
          this.editorContainerOffsetTop = document.querySelector('#editor_container').offsetTop;
          this.draggingContainer = document.createElement('div');
          
          let fieldWidth = "";
          let firstDraggingFieldHeight = ""
          for (let i = 0; i < this.draggingFields.length; i++) {
            const fieldNodeRef = 'field-' + this.draggingFields[i].field_index;
            const fieldNode = this.$refs[fieldNodeRef][0];
            if (i === 0) firstDraggingFieldHeight = fieldNode.offsetHeight; 
            const fieldNodeClone = fieldNode.cloneNode(true);
            this.draggingContainer.appendChild(fieldNodeClone);
            if (!fieldWidth) fieldWidth = fieldNode.clientWidth;
          }
    
          this.draggingContainer.style.position = "absolute";
          this.draggingContainer.style.width = fieldWidth + 'px';
          this.draggingContainer.style.top = e.pageY - (firstDraggingFieldHeight / 2) + 'px';


          fieldsContainer.appendChild(this.draggingContainer);

          this.updateFieldsOffsets();
          this.setNeighbors(field);
    
          this.startPoint = e.pageY;
        });
      }, animationTimeout);
    },
    updateFieldsOffsets() {
      this.fieldsOffsets = [];
      for (let i = 0; i < this.currentBannerFields.length; i++) {
        const field = this.currentBannerFields[i];
        const fieldNodeRef = 'field-' + field.field_index;
        const fieldNode = this.$refs[fieldNodeRef][0];
        const fieldOffsetCenter = fieldNode.offsetTop + (fieldNode.offsetHeight / 2)
        const fieldOffset = {
          offsetCenter: fieldOffsetCenter,
          field
        }
        this.fieldsOffsets.push(fieldOffset)
      }
    },
    onDoDragging(e) {
      if (this.draggingContainer && e.pageY > this.editorContainerOffsetTop) {
        const passedDistance = e.pageY - this.startPoint;
        // console.log('passedDistance: ', passedDistance);
        const draggingField = this.draggingFields[0];
        const firstDraggingFieldRef = 'field-' + this.draggingFields[0].field_index;
        const firstDraggingFieldHeight = this.$refs[firstDraggingFieldRef][0].offsetHeight;
        this.draggingContainer.style.top = e.pageY - (firstDraggingFieldHeight / 2) + 'px';

        if (this.neighbourBottom && (passedDistance > this.neighbourBottom.distance)) {
          this.startPoint = e.pageY;

          let neighbourBottomFieldsIndexes = [this.neighbourBottom.field.field_index];
          if (this.neighbourBottom.field.type === 'combo' && this.neighbourBottom.field.id) {
            const neighbourBottomGroup = this.fieldsGroups.find(fG => fG.id === this.neighbourBottom.field.id);
            neighbourBottomFieldsIndexes = neighbourBottomGroup.fields_indexes;
          }
          // if (this.neighbourBottom.field.related_group_id) {
          //   const neighbourBottomGroup = this.fieldsGroups.find(fG => fG.id === this.neighbourBottom.field.related_group_id);
          //   neighbourBottomFieldsIndexes = neighbourBottomGroup.fields_indexes;
          // }
          for (let i = 0; i < this.draggingFields.length; i++) {
            this.$store.commit('removeCurrentBannerFieldByIndex', this.draggingFields[i].field_index);
          }
          for (let i = 0; i < this.draggingFields.length; i++) {
            this.$store.commit('addCurrentBannerField', {
              field: this.draggingFields[i],
              field_index: this.draggingFields[i].field_index + neighbourBottomFieldsIndexes.length
            })
          }
          this.updateFieldsIndexes();
          this.updateGroups();
          this.$nextTick(() => {
            setTimeout(() => {
              this.updateFieldsOffsets();
              this.setNeighbors(draggingField);
              this.updateTextareas();

              if (draggingField.type === 'combo') {
                this.updateGroupsBackgrounds()
              }
            }, 0);
          });
        } else if (this.neighbourTop && (passedDistance < this.neighbourTop.distance)) {
          this.startPoint = e.pageY;

          let neighbourTopFieldsIndexes = [this.neighbourTop.field.field_index];
          if (this.neighbourTop.field.type === 'combo' && this.neighbourTop.field.id) {
            const neighborTopGroup = this.fieldsGroups.find(fG => fG.id === this.neighbourTop.field.id);
            neighbourTopFieldsIndexes = neighborTopGroup.fields_indexes;
          }
          // if (this.neighbourTop.field.related_group_id) {
          //   const neighborTopGroup = this.fieldsGroups.find(fG => fG.id === this.neighbourTop.field.related_group_id);
          //   neighbourTopFieldsIndexes = neighborTopGroup.fields_indexes;
          // }

          for (let i = 0; i < this.draggingFields.length; i++) {
            this.$store.commit('removeCurrentBannerFieldByIndex', this.draggingFields[i].field_index);
          }
          for (let i = 0; i < this.draggingFields.length; i++) {
            this.$store.commit('addCurrentBannerField', {
              field: this.draggingFields[i],
              field_index: neighbourTopFieldsIndexes[0] + i
            });
          }
          this.updateFieldsIndexes();
          this.updateGroups();
          this.$nextTick(() => {
            setTimeout(() => {
              this.updateFieldsOffsets();
              this.setNeighbors(draggingField);
              this.updateTextareas();

              if (draggingField.type === 'combo') {
                this.updateGroupsBackgrounds()
              }
            }, 0);
          });
        }
      }
    },
    onStopDragging() {
      if (this.draggingTimeout) {
        clearTimeout(this.draggingTimeout);
      }
      if (this.draggingContainer) {
        this.draggingContainer.remove();
        this.draggingContainer = '';
        this.neighbourBottom = null;
        this.neighbourTop = null;
        this.fieldsOffsets = [];
        this.editorContainerOffsetTop = '';
        this.draggingFields = [];
      }
    },
    setNeighbors(field) {
      this.neighbourTop = null;
      this.neighbourBottom = null;

      const draggingFieldIndex = field.field_index;
      const draggingFieldOffsetCenter = this.fieldsOffsets[draggingFieldIndex].offsetCenter;

      let neighbourTopIndex = draggingFieldIndex - 1;
      let neighbourBottomIndex = draggingFieldIndex + 1;

      if (this.currentBannerFields[neighbourTopIndex]) {
        const relatedGroupId = this.currentBannerFields[neighbourTopIndex].related_group_id; 
        if (relatedGroupId) {
          neighbourTopIndex = this.currentBannerFields.findIndex(f => f.id && f.id === relatedGroupId);
        }
        this.neighbourTop = this.fieldsOffsets[neighbourTopIndex];
        this.neighbourTop.distance = (draggingFieldOffsetCenter - this.neighbourTop.offsetCenter) * -1;
      } 
      
      while (this.currentBannerFields[neighbourBottomIndex] && this.currentBannerFields[neighbourBottomIndex].related_group_id) {
        neighbourBottomIndex++;
      }

      if (this.currentBannerFields[neighbourBottomIndex]) {
        this.neighbourBottom = this.fieldsOffsets[neighbourBottomIndex];
        this.neighbourBottom.distance = (this.neighbourBottom.offsetCenter - draggingFieldOffsetCenter);
      }
    },
    updateGroups() {
      const fields = this.currentBannerFields;
      this.fieldsGroups = [];

      for (let i = 0; i < fields.length; i++) {
        if (fields[i].type === 'combo') {
          this.fieldsGroups.push({ 
            id: fields[i].id,
            fields_indexes: [fields[i].field_index]  
          });
        } else if (fields[i].related_group_id) {
          const fieldsGroupIndex = this.fieldsGroups.findIndex(group => group.id === fields[i].related_group_id)
          this.fieldsGroups[fieldsGroupIndex].fields_indexes.push(fields[i].field_index);
        }
      }
    },
    onSetActiveFeedbackStyle(feedbackStyle) {
      this.activeField.value = 'assetId=' + feedbackStyle.id;
    },
    isFeedbackAvaliable() {
      const findIndex = this.currentBannerFields.findIndex(field => field.type === 'feedback');
      return findIndex === -1 ? true : false;
    }
  },
  mounted() {
    this.updateGroups()
    for (let i = 0; i < this.fieldsGroups.length; i++) {
      for (let j = 1; j < this.fieldsGroups[i].fields_indexes.length; j++) {
        const fieldRef = 'field-' + this.fieldsGroups[i].fields_indexes[j];
        const fieldNode = this.$refs[fieldRef][0];
        fieldNode.classList.add('hidden');
      }
    }
  },
  watch: {
    stopDragging() {
      this.onStopDragging();
    }
  },
  computed: {
    currentBannerFields() {
      return this.$store.getters['currentBannerFields'];
    },
    loader: {
      get() {
        return this.$store.getters['loader'];
      },
      set(value) {
        this.$store.commit('updateLoader', value);
      }
    },
    autoSaveQuery: {
      get() {
        return this.$store.getters.autoSaveQuery;
      },
      set(value) {
        this.$store.commit('updateAutoSaveQuery', value);
      }
    },
    activeFieldIndex: {
      get() {
        return this.$store.getters['activeFieldIndex']
      },
      set(index) {
        this.$store.commit('updateActiveFieldIndex', index);
      }
    },
    activeField() {
      return this.currentBannerFields[this.activeFieldIndex] || {};
    },
    currentApp() {
      return this.$store.getters.currentApp;
    }
  }
}
</script>

<style lang="sass" scoped>
@import '../assets/mixins/mixins'

.editor
  &__field-types
    display: flex
    justify-content: space-around
    padding: 0 0 35px
    position: relative
    .new-field-loader
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      display: flex
      justify-content: center
      align-items: center
      background: rgba(#fff, 0.7)
    .add-to-group
      display: flex
      align-items: center
      font-size: 14px
      font-family: $lato
      color: $pink
    .field-type
      width: 28px
      height: 28px
      background-size: contain
      background-repeat: no-repeat
      position: relative
      &__name
        position: absolute
        white-space: nowrap
        bottom: -15px
        left: 50%
        transform: translateX(-50%)
        font-family: $lato
        color: $pink
        text-transform: uppercase
        font-size: 9px
        opacity: 1
        transition: .1s linear
      &:hover
        cursor: pointer
        .field-type__name
          opacity: 1
          color: $red
      &_date
        background-image: url('../assets/img/data.png')
        &:hover
          background-image: url('../assets/img/data_hover.png')
      &_headerh1,
      &_headerh1group
        background-image: url('../assets/img/h1-header.png')
        &:hover
          background-image: url('../assets/img/h1-header_hover.png')
      &_headerh2,
      &_headerh2group
        background-image: url('../assets/img/h2-header.png')
        &:hover
          background-image: url('../assets/img/h2-header_hover.png')
      &_text,
      &_textgroup
        background-image: url('../assets/img/text.png')
        &:hover
          background-image: url('../assets/img/text_hover.png')
      &_image,
      &_imagegroup
        background-image: url('../assets/img/image.png')
        &:hover
          background-image: url('../assets/img/image_hover.png')
      &_youtube,
      &_youtubegroup
        background-image: url('../assets/img/youtube.png')
        &:hover
          background-image: url('../assets/img/youtube_hover.png')
      &_bulletpoints,
      &_bulletpointsgroup
        background-image: url('../assets/img/bullets.png')
        &:hover
          background-image: url('../assets/img/bullets_hover.png')
      &_combo
        background-image: url('../assets/img/combo.png')
        &:hover
          background-image: url('../assets/img/combo_hover.png')
      &_feedback
        background-image: url('../assets/img/bullets.png')
        &:hover
          background-image: url('../assets/img/bullets_hover.png')

  &__fields  
    // position: relative
    .field
      background-color: #fff
      border: 1px solid $grey-title
      transition: .1s linear
      box-shadow: 14.420px 14.420px 20px 0px rgba(93, 93, 93, 0.15)
      font-family: $lato
      color: $grey-text
      margin-bottom: 10px
      display: flex
      flex-direction: column
      position: relative
      z-index: 0
      &.dragging
        background-color: #e8e8e8
      .field-container
        padding: 15px 15px
        display: flex
        align-items: center
      &:hover
        border-color: $red
        .field-button
          opacity: 1
      &.active
        background-color: $pink-light
        border-color: $grey-text
        z-index: 9
      &.showed
        max-height: 350px
        border-width: 1px
        margin-bottom: 10px
        transition: 1s ease
        transition: margin-bottom .2s ease
        transform: scaleY(1)
      &.hidden
        max-height: 0
        overflow: hidden
        border-width: 0
        margin-bottom: 0
        transform: scaleY(0.5)
        transition: .4s ease
    .field-button
      background: transparent
      border: none
      background-size: contain
      background-repeat: no-repeat
      opacity: 0
      transition: .1s linear
      &_move
        background-image: url('../assets/img/move.png')
        margin-right: 15px
        width: 13px
        height: 17px
        &:hover
          cursor: grab
          background-image: url('../assets/img/move_hover.png')
      &_delete
        background-image: url('../assets/img/delete.png')
        margin-left: 15px
        width: 15px
        height: 15px
        &:hover
          cursor: pointer
          background-image: url('../assets/img/delete_hover.png')
    .group-index
      display: block
      width: 14px
      transition: .1s linear
      font-size: 14px
      margin-right: 15px
      color: $red
    .field-image
      width: 28px
      height: 26px
      background-size: contain
      background-repeat: no-repeat
      margin-right: 15px
      &.date
        background-image: url('../assets/img/data-grey.png')
      &.headerh1,
      &.headerh1group
        background-image: url('../assets/img/h1-header-grey.png')
      &.headerh2,
      &.headerh2group
        background-image: url('../assets/img/h2-header-grey.png')
      &.text,
      &.textgroup 
        background-image: url('../assets/img/text-grey.png')
      &.image,
      &.imagegroup 
        background-image: url('../assets/img/image-grey.png')
      &.youtube,
      &.youtubegroup 
        background-image: url('../assets/img/youtube-grey.png')
      &.bulletpoints,
      &.bulletpointsgroup
        background-image: url('../assets/img/bullets-grey.png')
      &.combo
        background-image: url('../assets/img/combo-grey.png')
      &.feedback
        background-image: url('../assets/img/bullets-grey.png')

.toolbar-enter, .toolbar-leave-to
  max-height: 0
  transform: scaleY(0)

.toolbar-enter-active, .toolbar-leave-active
  transition: all 0.2s ease
  
@media screen and (max-width: 1240px)
  .editor
    &__fields
      .field-container
        .field-button
          &_move
              display: none

@media screen and (max-width: 680px)
  .editor
    overflow-x: hidden
    &__field-types
      flex-wrap: wrap
      padding-bottom: 20px
      justify-content: flex-start
      .field-type
        margin: 0 calc((100% - 112px) / 8) 30px
    &__fields
      .toolbar-container
        display: none
      .field-container
        .field-button
          &_move
            display: none

</style>
