<template>
	<div class="feedback-modal">
    <transition name="message" mode="out-in">
      <div class="feedback-modal__error" v-if="error">{{ error }}</div>
    </transition>

		<h2 class="feedback-modal__title">Custom feedback</h2>

		<form class="feedback-modal__form" @submit.prevent="onSubmit">
			<div class="input-container">
				<label class="input-title">Custom sets: </label>
				<div 
          class="input-list" 
          @mouseover="showSetsList = true" 
          @mouseleave="showSetsList = false"
        >
					<div class="input-list__active">
            <span style="margin-right: 10px" v-if="activeSet.name === 'New'">New</span>
            <img
              class="feedback-icon"
              v-for="(icon, index) in Object.keys(activeSet.urls)"
              :key="index"
              :src="getImageSrc(icon)"
              :style="{'display': isImage(icon) ? 'inline-block' : 'none'}"
            >
          </div>
          <transition name="sets-list" mode="in-out">
            <ul class="input-list__dropdown" v-show="showSetsList">
              <li
                class="input-list__dropdown-item"
                v-for="(customIconStyle, index) in customIconStyles"
                :key="index"
                @click="onFetchSet(customIconStyle, index)"
                :style="{'display': activeSet.name === 'Custom' + index ? 'none' : 'block'}"
              >
                <img 
                  class="feedback-icon"
                  v-for="(iconLink, index) in Object.values(customIconStyle.data)"
                  :key="index"
                  :style="{'display': iconLink.startsWith('/uploads/') ? 'inline-block' : 'none'}"
                  :src="iconLink ? ENV_STRAPI_HOST + iconLink : ''" 
                >
              </li>
              <li
                class="input-list__dropdown-item"
                v-if="activeSet.name !== 'New'"
                @click="onFetchSet('New')"
              >New</li>
            </ul>
          </transition>
				</div>
			</div>

      <transition name="sets-list" mode="in-out">
        <div class="form-main-content" v-show="!showSetsList">
          <!-- FEEDBACK GOOD -->
          <section class="feedback-section">
            <div class="input-container">
              <label class="input-title">Feedback good: </label>
              <input 
                class="input-checkbox"
                type="checkbox"
                ref="checkbox-good"
                :checked="isChecked.good"
                @change="isChecked.good = !isChecked.good"
              >
            </div>

            <div class="input-container" v-if="isChecked.good">
              <label class="input-title">Default icon: </label>
              <div class="input-image-container">
                <img
                  class="image" 
                  v-if="isImage('goodDefault')"
                  :src="getImageSrc('goodDefault')"
                >
                <button
                  class="button-image"
                  type="button"
                  @click="$refs['input_image_good_default'].click()"
                >{{ isImage('goodDefault') ? 'Change' : 'Add' }}</button>
                <input
                  class="input-image"
                  type="file"
                  accept="image/*"
                  @change="onImageChange($event, 'goodDefault')"
                  ref="input_image_good_default"
                >
              </div> 
            </div>
            <div class="input-container" v-if="isChecked.good">
              <label class="input-title">Hover icon: </label>
              <div class="input-image-container">
                <img 
                  class="image"
                  v-if="isImage('goodHover')"
                  :src="getImageSrc('goodHover')"
                >
                <button
                  class="button-image"
                  type="button"
                  @click="$refs['input_image_good_hover'].click()"
                >{{ isImage('goodHover') ? 'Change' : 'Add' }}</button>
                <input
                  class="input-image"
                  type="file"
                  accept="image/*"
                  @change="onImageChange($event, 'goodHover')"
                  ref="input_image_good_hover"
                >
              </div>
            </div>
            <div class="input-container" v-if="isChecked.good">
              <label class="input-title">Active icon: </label>
              <div class="input-image-container">
                <img 
                  class="image"
                  v-if="isImage('goodActive')"
                  :src="getImageSrc('goodActive')"
                >
                <button
                  class="button-image"
                  type="button"
                  @click="$refs['input_image_good_active'].click()"
                >{{ isImage('goodActive') ? 'Change' : 'Add' }}</button>
                <input
                  class="input-image"
                  type="file"
                  accept="image/*"
                  @change="onImageChange($event, 'goodActive')"
                  ref="input_image_good_active"
                >
              </div>
            </div>
            <div class="input-container" v-if="isChecked.good">
              <label class="input-title">Title: </label>
              <input
                  class="input-text"
                  type="text"
                  ref="input_title_good"
                  placeholder="Title for good icon (optional)"
                  v-model="activeSet.titles.good"
                >
            </div>
          </section>
          <!-- FEEDBACK NEUTRAL -->
          <section class="feedback-section">
            <div class="input-container">
              <label class="input-title">Feedback neutral: </label>
              <input 
                class="input-checkbox"
                type="checkbox"
                ref="checkbox-good"
                :checked="isChecked.neutral"
                @change="isChecked.neutral = !isChecked.neutral"
              >
            </div>
            <div class="input-container" v-if="isChecked.neutral">
              <label class="input-title">Default icon: </label>
              <div class="input-image-container">
                <img 
                  class="image"
                  v-if="isImage('neutralDefault')"
                  :src="getImageSrc('neutralDefault')"
                >
                <button
                  class="button-image"
                  type="button"
                  @click="$refs['input_image_neutral_default'].click()"
                >{{ isImage('neutralDefault') ? 'Change' : 'Add' }}</button>
                <input
                  class="input-image"
                  type="file"
                  accept="image/*"
                  @change="onImageChange($event, 'neutralDefault')"
                  ref="input_image_neutral_default"
                >
              </div>
            </div>
            <div class="input-container" v-if="isChecked.neutral">
              <label class="input-title">Hover icon: </label>
              <div class="input-image-container">
                <img 
                  class="image"
                  v-if="isImage('neutralHover')"
                  :src="getImageSrc('neutralHover')"
                >
                <button
                  class="button-image"
                  type="button"
                  @click="$refs['input_image_neutral_hover'].click()"
                >{{ isImage('neutralHover') ? 'Change' : 'Add' }}</button>
                <input
                  class="input-image"
                  type="file"
                  accept="image/*"
                  @change="onImageChange($event, 'neutralHover')"
                  ref="input_image_neutral_hover"
                >
              </div>
            </div>
            <div class="input-container" v-if="isChecked.neutral">
              <label class="input-title">Active icon: </label>
              <div class="input-image-container">
                <img 
                  class="image"
                  v-if="isImage('neutralActive')"
                  :src="getImageSrc('neutralActive')"
                >
                <button
                  class="button-image"
                  type="button"
                  @click="$refs['input_image_neutral_active'].click()"
                  v-if="isChecked.neutral"
                >{{ isImage('neutralActive') ? 'Change' : 'Add' }}</button>
                <input
                  class="input-image"
                  type="file"
                  accept="image/*"
                  @change="onImageChange($event, 'neutralActive')"
                  ref="input_image_neutral_active"
                >
              </div>
            </div>
            <div class="input-container" v-if="isChecked.neutral">
              <label class="input-title">Title: </label>
              <input
                class="input-text"
                type="text"
                ref="input_title_neutral"
                placeholder="Title for neutral icon (optional)"
                v-model="activeSet.titles.neutral"
              >
            </div>
          </section>
          <!-- FEEDBACK BAD -->
          <section class="feedback-section">
            <div class="input-container">
              <label class="input-title">Feedback bad: </label>
              <input 
                class="input-checkbox"
                type="checkbox"
                ref="checkbox-good"
                :checked="isChecked.bad"
                @change="isChecked.bad = !isChecked.bad"
              >
            </div>
            <div class="input-container" v-if="isChecked.bad">
              <label class="input-title">Default icon: </label>
              <div class="input-image-container">
                <img 
                  class="image"
                  v-if="isImage('badDefault')"
                  :src="getImageSrc('badDefault')"
                >
                <button
                  class="button-image"
                  type="button"
                  @click="$refs['input_image_bad_default'].click()"
                >{{ isImage('badDefault') ? 'Change' : 'Add' }}</button>
                <input
                  class="input-image"
                  type="file"
                  accept="image/*"
                  @change="onImageChange($event, 'badDefault')"
                  ref="input_image_bad_default"
                >
              </div>
            </div>
            <div class="input-container" v-if="isChecked.bad">
              <label class="input-title">Hover icon: </label>
              <div class="input-image-container">
                <img 
                  class="image"
                  v-if="isImage('badHover')"
                  :src="getImageSrc('badHover')"
                >
                <button
                  class="button-image"
                  type="button"
                  @click="$refs['input_image_bad_hover'].click()"
                >{{ isImage('badHover') ? 'Change' : 'Add' }}</button>
                <input
                  class="input-image"
                  type="file"
                  accept="image/*"
                  @change="onImageChange($event, 'badHover')"
                  ref="input_image_bad_hover"
                >
              </div>
            </div>
            <div class="input-container" v-if="isChecked.bad">
              <label class="input-title">Active icon: </label>
              <div class="input-image-container">
                <img 
                  class="image"
                  v-if="isImage('badActive')"
                  :src="getImageSrc('badActive')"
                >
                <button
                  class="button-image"
                  type="button"
                  @click="$refs['input_image_bad_active'].click()"
                >{{ isImage('badActive') ? 'Change' : 'Add' }}</button>
                <input
                  class="input-image"
                  type="file"
                  accept="image/*"
                  @change="onImageChange($event, 'badActive')"
                  ref="input_image_bad_active"
                >
              </div>
            </div>
            <div class="input-container" v-if="isChecked.bad">
              <label class="input-title">Title: </label>
              <input
                class="input-text"
                type="text"
                ref="input_title_bad"
                placeholder="Title for bad icon (optional)"
                v-model="activeSet.titles.bad"
              >
            </div>
          </section>

          <div class="loader-container" v-if="loader">
            <img :src="require('../../assets/img/autosave_saving.gif')">
          </div>
          <div class="buttons-container" v-if="!loader">
            <button
              class="input-button input-button_delete"
              type="button"
              v-if="activeSet.name !== 'New'"
              @click="onDeleteActiveSet"
            >Delete</button>
            <button
              class="input-button"
              type="button"
              @click="$emit('close', false)"
            >Cancel</button>
            <button
              class="input-button"
              type="submit"
            >Save</button>
          </div>
        </div>
      </transition>
		</form>
	</div>
</template>

<script>
import ModalMessageMixin from '@/mixins/ModalMessageMixin';

export default {
	name: "FeedbackModal",
  mixins: [ModalMessageMixin],
  props: ['activeField'],
	data: () => ({
    loader: false,
    showSetsList: false,
    isChecked: {
      good: true,
      neutral: true,
      bad: true
    },
    activeSet: {
      name: 'New',
      urls: {
        'goodDefault': '',
        'goodHover': '',
        'goodActive': '',
        'neutralDefault': '',
        'neutralHover': '',
        'neutralActive': '',
        'badDefault': '',
        'badHover': '',
        'badActive': ''
      },
      newIcons: {
        goodDefault: null,
        goodHover: null,
        goodActive: null,
        neutralDefault: null,
        neutralHover: null,
        neutralActive: null,
        badDefault: null,
        badHover: null,
        badActive: null
      },
      titles: {
        good: '',
        neutral: '',
        bad: ''
      }
    }
	}),
	methods: {
    onFetchSet(set, index) {
      if (typeof set === 'string' && set === 'New') {
        this.activeSet = {
          name: 'New',
          urls: {
            'goodDefault': '',
            'goodHover': '',
            'goodActive': '',
            'neutralDefault': '',
            'neutralHover': '',
            'neutralActive': '',
            'badDefault': '',
            'badHover': '',
            'badActive': ''
          },
          newIcons: {
            goodDefault: null,
            goodHover: null,
            goodActive: null,
            neutralDefault: null,
            neutralHover: null,
            neutralActive: null,
            badDefault: null,
            badHover: null,
            badActive: null
          },
          titles: {
            good: '',
            neutral: '',
            bad: ''
          }
        }
      } else {
        this.activeSet = {
          id: set.id,
          name: 'Custom' + index,
          urls: {
            'goodDefault': set.data['goodDefault'],
            'goodHover': set.data['goodHover'],
            'goodActive': set.data['goodActive'],
            'neutralDefault': set.data['neutralDefault'],
            'neutralHover': set.data['neutralHover'],
            'neutralActive': set.data['neutralActive'],
            'badDefault': set.data['badDefault'],
            'badHover': set.data['badHover'],
            'badActive': set.data['badActive']
          },
          newIcons: {
            goodDefault: null,
            goodHover: null,
            goodActive: null,
            neutralDefault: null,
            neutralHover: null,
            neutralActive: null,
            badDefault: null,
            badHover: null,
            badActive: null
          },
          titles: {
            good: set.data['goodTitle'],
            neutral: set.data['neutralTitle'],
            bad: set.data['badTitle']
          }
        }
      }
    },
    isImage(type) {
      return this.activeSet.urls[type] || this.activeSet.newIcons[type];
    },
    getImageSrc(type) {
      return this.activeSet.newIcons[type]
             ? URL.createObjectURL(this.activeSet.newIcons[type])
             : this.ENV_STRAPI_HOST + this.activeSet.urls[type];
    },
    onImageChange(e, type) {
      const targetImage = e.target.files[0];
      if (targetImage.size > 1048576) {
        this.setMessage('error', 'Maximum image size - 1MB');
        this.$refs['input_image'].value = "";
        return;
      }
			this.activeSet.newIcons[type] = targetImage;
    },
		async onSubmit() {
      try {
        this.loader = true;
        const checkedTypes = ['good', 'neutral', 'bad'];

        if (!this.isChecked.good && !this.isChecked.neutral && !this.isChecked.bad) {
          this.setMessage('error', 'At least one feedback required');
          return;
        }

        for (let i = 0; i < checkedTypes.length; i++) {
          const type = checkedTypes[i];
          // Validate
          if (this.isChecked[type]) {
            if (!this.activeSet.urls[type + 'Default'] && !this.activeSet.newIcons[type + 'Default']) {
              this.setMessage('error', 'Feedback ' + checkedTypes[i] + ' default icon is required');
              return;
            }

            if (!this.activeSet.urls[type + 'Hover'] && !this.activeSet.newIcons[type + 'Hover']) {
              this.setMessage('error', 'Feedback ' + checkedTypes[i] + ' hover icon is required');
              return;
            }
            
            if (!this.activeSet.urls[type + 'Active'] && !this.activeSet.newIcons[type + 'Active']) {
              this.setMessage('error', 'Feedback ' + checkedTypes[i] + ' active icon is required');
              return;
            }
          }
        }

        for (let i = 0; i < checkedTypes.length; i++) {
          const type = checkedTypes[i];
          // Delete unchecked
          if (!this.isChecked[type]) {
            if (this.activeSet.urls[type + 'Default']) {
              await this.deleteImageByUrl(this.activeSet.urls[type + 'Default']);
              this.activeSet.urls[type + 'Default'] = "";
            }
            if (this.activeSet.urls[type + 'Hover']) {
              await this.deleteImageByUrl(this.activeSet.urls[type + 'Hover']);
              this.activeSet.urls[type + 'Hover'] = "";
            }
            if (this.activeSet.urls[type + 'Active']) {
              await this.deleteImageByUrl(this.activeSet.urls[type + 'Active']);
              this.activeSet.urls[type + 'Active'] = "";
            }
          }
          // Upload new icon
          if (this.isChecked[type]) {
            if (this.activeSet.newIcons[type + 'Default']) {
              if (this.activeSet.urls[type + 'Default'])  {
                await this.deleteImageByUrl(this.activeSet.urls[type + 'Default']);
                this.activeSet.urls[type + 'Default'] = "";
              }
              const newImage = await this.$store.dispatch('postImage', this.activeSet.newIcons[type + 'Default']);
              this.activeSet.urls[type + 'Default'] = newImage.url;
            }
            if (this.activeSet.newIcons[type + 'Hover']) {
              if (this.activeSet.urls[type + 'Hover'])  {
                await this.deleteImageByUrl(this.activeSet.urls[type + 'Hover']);
                this.activeSet.urls[type + 'Hover'] = "";
              }
              const newImage = await this.$store.dispatch('postImage', this.activeSet.newIcons[type + 'Hover']);
              this.activeSet.urls[type + 'Hover'] = newImage.url;
            }
            if (this.activeSet.newIcons[type + 'Active']) {
              if (this.activeSet.urls[type + 'Active']) {
               await this.deleteImageByUrl(this.activeSet.urls[type + 'Active']);
                this.activeSet.urls[type + 'Active'] = "";
              }
              const newImage = await this.$store.dispatch('postImage', this.activeSet.newIcons[type + 'Active']);
              this.activeSet.urls[type + 'Active'] = newImage.url;
            }
          }
        }
        // Get titles
        const titles = {
          "goodTitle": this.activeSet.titles.good,
          "neutralTitle": this.activeSet.titles.neutral,
          "badTitle": this.activeSet.titles.bad
        }
  
        const currentTeamCopy = Object.assign({}, this.$store.getters.currentTeam);
        const asset = {
          name: 'feedbackIconsCustom',
          data: Object.assign(this.activeSet.urls, titles),
          team: currentTeamCopy.id
        };
        // Post or update asset
        let savedAssetId;
        if (this.activeSet.name === 'New') {
          const newAsset = await this.$store.dispatch('postAsset', { 
            asset, 
            strapiLink: this.ENV_STRAPI_HOST, 
            jwt: this.$store.getters.token 
          });
          currentTeamCopy.assets.push(newAsset);
          savedAssetId = newAsset.id;
        } else if (this.activeSet.name.startsWith('Custom')) {
          const updatedAsset = await this.$store.dispatch('updateAsset', { asset, assetId: this.activeSet.id });
          currentTeamCopy.assets = currentTeamCopy.assets.map(a => a.id === updatedAsset.id ? updatedAsset : a);
          savedAssetId = updatedAsset.id;
        }
        // Update state
        this.$store.commit('updateCurrentTeam', currentTeamCopy);
        await this.$store.dispatch('fetchTeamFeedbackIcons');
        // Set asset as active
        if (savedAssetId) {
          this.$emit('close', { ...this.activeField, value: 'assetId=' + savedAssetId });
        } else {
          this.$emit('close', null);
        }
      } catch (e) {
        console.log('Error on save: ', e);
        // this.setMessage('error', e);
      } finally {
        this.loader = false;
      }
		},
    async deleteImageByUrl(imageUrl) {
      const imageObj = await this.$store.dispatch('fetchImageObjectByURL', imageUrl);
      await this.$store.dispatch('deleteImage', imageObj.id);
    },
    async onDeleteActiveSet() {
      try {
        this.loader = true;
        // Delete images
        for (const url of Object.values(this.activeSet.urls)) {
          if (url) {
            await this.deleteImageByUrl(url);
          }
        }
        // Delete assets
        const deletedAsset = await this.$store.dispatch('deleteAssetById', this.activeSet.id);
        const currentTeamCopy = Object.assign({}, this.$store.getters.currentTeam); 
        currentTeamCopy.assets = currentTeamCopy.assets.filter(a => a.id !== deletedAsset.id);
        // Update state
        this.$store.commit('updateCurrentTeam', currentTeamCopy);
        await this.$store.dispatch('fetchTeamFeedbackIcons');
        // Check if deleted asset was active then change active assset
        const activeAssetId = (this.activeField.value.split('='))[1];
        if (activeAssetId && activeAssetId == deletedAsset.id) {
          const teamFeedbackIcons = this.$store.getters.teamFeedbackIcons;
          this.$emit('close', { ...this.activeField, value: 'assetId=' + teamFeedbackIcons[0].id })
        } else {
          this.$emit('close', null);
        }
      } catch (e) {
        console.log('Error on delete: ', e);
      } finally {
        this.loader = false;
      }
    }
	},
  computed: {
    customIconStyles() {
      return this.$store.getters.currentTeam.assets;
    }
  },
  watch: {
    activeSet: {
      handler(activeSet) {
        if (activeSet.name.startsWith('Custom')) {
        const types = ['goodDefault', 'neutralDefault', 'badDefault'];
        types.forEach(type => {
            if (!activeSet.urls[type].length) {
              this.isChecked[type.replace('Default', '')] = false;
            }
          });
        } else if (activeSet.name === 'New') {
          this.isChecked = {
            good: true,
            neutral: true,
            bad: true
          }
        }
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@import '../../assets/mixins/mixins'

.feedback-modal
  @include modal
  .input-container
    .input-list
      position: relative
      flex: 1
      &:hover
        .input-list__active
          border-color: $red
          &::after
            background-image: url('../../assets/img/arrow-down_hover.png')
      &__active
        font-size: 15px
        padding: 5px 0
        border-bottom: 1px solid $pink
        position: relative
        &::after
          content: ''
          display: block
          width: 15px
          height: 15px
          background-image: url('../../assets/img/arrow-down.png')
          background-repeat: no-repeat
          background-size: contain
          position: absolute
          top: 50%
          transform: translateY(-50%)
          right: 5px
      &__dropdown
        position: absolute
        top: 100%
        left: 0
        width: 100%
      &__dropdown-item
        display: flex
        padding: 8px 0
        border-bottom: 1px solid $pink
        &:hover
          cursor: pointer
          border-color: $red
      .feedback-icon
        width: 16px
        height: 16px
        &:not(:last-child)
          margin-right: 5px

  .form-main-content
    .feedback-section
      .input-container      
        margin-bottom: 20px
    
      

  .input-image-container
    display: flex
    align-items: center
    .image
      width: 48px
      height: 48px
      margin-right: 20px
    .button-image
      background: none
      color: $pink
      border: none
      font-size: 15px
      text-transform: uppercase
      transition: .1s linear
      &:hover
        cursor: pointer
        color: $red
    .input-image
      display: none
  .input-checkbox
    margin-right: 20px
  .buttons-container
    .input-button
      &_delete
        margin-right: auto

.sets-list-enter, .sets-list-leave-to
  opacity: 0

.sets-list-enter-active, .sets-list-leave-active
  transition: all .2s ease

.message-enter, .message-leave-to
  opacity: 0

.message-enter-active, .message-leave-active
  transition: all .2s ease
</style>